import React from 'react';
import { ColorButtonMain } from '../utils/theme';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Green, OrangeMain } from '../utils/theme';
import EventIcon from '@material-ui/icons/Event';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ButtonMain from '../components/commons/button-main';
import { Fragment } from 'react';
import moment from 'moment';
import AlertTitle from '../components/commons/Alert-title';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  status: {
    color: (props) => (props.status === 'active' ? Green : 'red'),
    marginBottom: 10,
    fontWeight: 'bold',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'left',
  },
  items: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '6px 0px',
    '& > p': {
      margin: 0,
    },
  },
  buttonMain: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
    textAlign: 'center',
  },
  line: {
    width: '100%',
    height: '1px',
    opacity: '0.5',
    backgroundColor: ColorButtonMain,
    margin: '0px 4px',
  },
  contentLine: {
    margin: '10px 0px',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
});

export default function ScheduleClass(props) {
  const classes = useStyles();
  const { student_schedule } = props.student;

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {student_schedule?.map((it) => (
          <Fragment>
            <div
              className={classes.status}
              style={{
                color:
                  it.status === 'pre_schedule'
                    ? OrangeMain
                    : it.status === 'schedule' || it.status == 'assisted'
                    ? Green
                    : '#D70000',
              }}
            >
              {it.status === 'pre_schedule'
                ? 'Pre Programada'
                : it.status === 'schedule'
                ? 'Programada'
                : it.status === 'assisted'
                ? 'Recibida'
                : it.status === 'not_assisted'
                ? 'No asistío'
                : 'Cancelada'}
            </div>
            <div className={classes.items}>
              <EventIcon />
              <p style={{ marginLeft: 5, fontWeight: 'bold' }}>
                {moment(it.start_date_time).utcOffset(+5).format('dddd, D MMMM YYYY')}
              </p>
            </div>
            <div className={classes.items}>
              <ScheduleIcon />
              <p style={{ marginLeft: 5 }}>
                {`
                ${moment(it.start_hour, 'hh:mm').format('h:mm a')} a 
                ${moment(it.end_hour, 'hh:mm').format('h:mm a')}`}
              </p>
            </div>
            <div className={classes.contentLine}>
              <div className={classes.line}></div>
            </div>
          </Fragment>
        ))}
        {props.student.user_subscription?.status === 'active' ? (
          <Link to={`/pre-register-class/${props.student.user_subscription.subscription_id}?studentId=${props.student.id}&edit=${'true'}`}>
            <div className={classes.buttonMain}>
              <ButtonMain label="Agendar clases" style={{ width: 200 }} />
            </div>
          </Link>
        ) : (
          <div>
            <AlertTitle
              severity="warning"
              title="Debes obtener una subscripción"
              style={{ fontWeight: 700, fontSize: 16 }}
              iconSize={28}
            />
          </div>
        )}
      </div>
    </div>
  );
}
