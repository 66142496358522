import feathers from "@feathersjs/feathers";
import auth from "@feathersjs/authentication-client";
import rest from "@feathersjs/rest-client";

import {
  ACCESS_TOKEN,
  HOST_API,
  PROTOCOL_HTTP,
  STORAGE,
  TIMEOUT,
} from "../utils/constants";

const app = feathers();

const restClient = rest(`${PROTOCOL_HTTP}://${HOST_API}`);
// const restClient = rest("https://api.watertownswimschool.com");

app.configure(restClient.fetch(window.fetch.bind(window)));

app.configure(
  auth({
    path: "/authentication",
    entity: "user",
    service: "users",
    timeout: TIMEOUT,
    cookie: ACCESS_TOKEN,
    storageKey: ACCESS_TOKEN,
    storage: STORAGE,
  })
);

export const retryLogin = () =>
  app.authenticate({
    strategy: "jwt",
    accessToken: STORAGE.getItem(ACCESS_TOKEN),
  });

export const logout = () => {
  return new Promise((resolve, rejected) => {
    STORAGE.removeItem("feathers-jwt");
    if (app.logout()) resolve();
    else rejected();
  });
};

export const cmsService = app.service("cms");
export const userService = app.service("users");
export const subscriptionsService = app.service("subscriptions");
export const creditCardsService = app.service("user-credit-cards");
export const authenticationService = app.service("authentication");
export const studentService = app.service("students");
export const userSubscriptionService = app.service("user-subscriptions");
export const studentScheduleService = app.service("student-schedule");
export const courseScheduleService = app.service("course-schedule");
export const studentAchievementsService = app.service("student-achievement");
export const levelService = app.service("levels");
export const programsService = app.service("programs");
export const ClientFeathers = app;

