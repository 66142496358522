import react, { useEffect } from 'react';
import { s3Path } from '../utils/constants';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  contentHeaderImage: {
    position: 'absolute',
    width: '100%',
    zIndex: -20,
    '& > img': {
      height: 210,
      objectFit: 'cover',
      objectPosition: 'center',
      opacity: 0.8,
      width: '100%',
    },
  },
  footer: {},
  contentFooterImage: {
    position: 'fixed',
    bottom: -200,
    zIndex: -20,
    '& > img': {
      height: 260,
      objectFit: 'cover',
      objectPosition: 'center',
      opacity: 0.8,
      width: 400,
    },
  },
});

function GreenGlobeCurve(props) {
  const classes = useStyles(props);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.contentHeaderImage}>
        <img
          src={`${window.imageShark(
            `static/images/backgrounds/greenGlobeCurve.png`,
            500,
            300
          )}`}
          alt="img"
        />
      </div>
      {props.component}
      <div className={classes.contentFooterImage}>
        <img
          src={`${window.imageShark(
            `static/images/backgrounds/purpureGlobe.png`,
            200,
            200
          )}`}
          alt="img"
        />
      </div>
    </div>
  );
}

export default GreenGlobeCurve;
