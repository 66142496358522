import React, { useEffect, useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '../../components/commons/BackIcon';
import { useHistory, useLocation } from 'react-router-dom';
import { BlueMainLetters } from '../../utils/theme';
import CheckoutForms from './CheckoutForms';

const useStyles = makeStyles((theme) => ({
  root: { width: '100%' },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingLeft: 20,
    paddingRight: 20,
  },
  rowBack: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
    color: BlueMainLetters,
    zIndex: 10,
  },
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: -20,
    color: BlueMainLetters,
  },
  totalPayment: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
  },
  contentButtonAdd: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 30,
    padding: '0px 30px 0px 30px',
  },
  contendList: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    minHeight: 50,
    alignItems: 'flex-end',
  },
  contentButtons: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  buttonOutline: {
    height: 36,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    border: `1px black solid`,
    color: 'black',
    justifyContent: 'center',
    fontWeight: '500',
    fontSize: 16,
    borderRadius: 20,
    marginTop: 16,
  },
}));

function Checkout(props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(searchParams);

  const previousPage = (props) => {
    history.goBack();
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.rowBack} onClick={() => previousPage(props)}>
          <div>
            <ArrowBackIcon />
          </div>
        </div>
        <CheckoutForms
          subscription_id={params.subscription}
          student_id={params.studentId}
        />
      </div>
    </div>
  );
}

export default Checkout;
