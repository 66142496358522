import { Formik } from "formik";
import React, { useState } from "react";
import { BlueMainLetters } from "../utils/theme";
import { studentService } from "../api/feathers";
import { makeStyles } from "@material-ui/core/styles";
import SnackBar from "../components/commons/snackbar";
import InputMain from "../components/commons/Input-main";
import ButtomMain from "../components/commons/button-main";
import RadioGroup from "../components/commons/Radio-group";

import { BlueSecondBorderInputs } from "../utils/theme";

const useStyles = makeStyles(theme => ({
  root: { width: "100%" },
  content: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  title: {
    color: BlueMainLetters,
  },
  input: {
    marginTop: 23,
  },
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  contendAvatar: {
    display: "flex",
    justifyContent: "center",
  },
  rowBack: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 10,
    color: BlueMainLetters,
  },
  contentCurrentSubscription: {
    width: "100%",
    margin: "20px 0px 20px 0px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  labelCurrentSubscription: {
    color: BlueMainLetters,
    fontWeight: "600",
    marginRight: 10,
  },
}));

function EditStudent(props) {
  const classes = useStyles();
  const [alertNotification, setAlertNotification] = useState(false);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div>
          <div className={classes.contendCreditCard}>
            <div>
              <div className={classes.contendForm}>
                {props.student.first_name ? (
                  <Formik
                    initialValues={{
                      special_conditions: props.student.special_conditions,
                      first_name: props.student.first_name,
                      last_name: props.student.last_name,
                      gender: props.student.gender,
                      birthday: props.student.birthday,
                      subscription_id: null,
                      special_conditions_description:
                        props.student.special_conditions_description,
                    }}
                    validate={values => {
                      const errors = {};

                      if (!values.first_name) {
                        errors.first_name = "Required";
                      }
                      if (!values.last_name) {
                        errors.last_name = "Required";
                      }
                      if (!values.gender) {
                        errors.gender = "Required";
                      }
                      if (!values.birthday) {
                        errors.birthday = "Required";
                      }

                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      studentService
                        .patch(props.student.id, {
                          ...values,
                        })
                        .then(it => {
                          props.setStudent(it);
                          setAlertNotification({
                            openAlert: true,
                            message: "Estudiante actualizado",
                            severity: "success",
                          });
                        })
                        .catch(it => {
                          setAlertNotification({
                            openAlert: true,
                            message: it.message,
                            severity: "error",
                          });
                        });
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className={classes.input}>
                          <InputMain
                            borderColor={BlueSecondBorderInputs}
                            name="first_name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.first_name}
                            label="Nombres"
                            error={errors.first_name && touched.first_name}
                          />
                        </div>
                        <div className={classes.input}>
                          <InputMain
                            borderColor={BlueSecondBorderInputs}
                            name="last_name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.last_name}
                            label="Apellidos"
                            error={errors.last_name && touched.last_name}
                          />
                        </div>
                        <div className={classes.input}>
                          <InputMain
                            type="date"
                            borderColor={BlueSecondBorderInputs}
                            name="birthday"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.birthday}
                            label="Fecha de nacimiento"
                            error={errors.birthday && touched.birthday}
                          />
                        </div>
                        <div className={classes.input}>
                          <RadioGroup
                            label="Género"
                            data={[
                              { label: "Masculino", value: "masculine" },
                              { label: "Femenino", value: "female" },
                            ]}
                            name="gender"
                            value={values.gender}
                            onChange={handleChange}
                            error={errors.gender && touched.gender}
                            onBlur={handleBlur}
                            flexDirection="row"
                          />
                        </div>
                        <div className={classes.input}>
                          <RadioGroup
                            label="Condiciones especiales"
                            data={[
                              { label: "Si", value: "true" },
                              { label: "No", value: "false" },
                            ]}
                            name="special_conditions"
                            value={values.special_conditions}
                            onChange={handleChange}
                            error={
                              errors.special_conditions &&
                              touched.special_conditions
                            }
                            onBlur={handleBlur}
                            flexDirection="row"
                          />
                        </div>
                        {values.special_conditions &&
                          values.special_conditions === "true" && (
                            <div>
                              <InputMain
                                borderColor={BlueSecondBorderInputs}
                                name="special_conditions_description"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.special_conditions_description}
                                multiline
                                rows={4}
                                placeholder="Placeholder"
                                error={
                                  errors.special_conditions_description &&
                                  touched.special_conditions_description
                                }
                              />
                            </div>
                          )}
                        <div></div>
                        <div style={{ marginTop: 35 }}>
                          <ButtomMain
                            label={`Guardar`}
                            type="submit"
                            disabled={isSubmitting}
                          ></ButtomMain>
                        </div>
                      </form>
                    )}
                  </Formik>
                ) : null}
              </div>
            </div>
          </div>
          <SnackBar
            openAlert={alertNotification.openAlert}
            message={alertNotification.message}
            severity={alertNotification.severity}
            handleClose={() => setAlertNotification({ openAlert: false })}
          />
        </div>
      </div>
    </div>
  );
}

export default EditStudent;
