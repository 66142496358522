import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import { BlueMainLetters, BlueSecondLetters } from "../utils/theme";
import MenuItem from "@material-ui/core/MenuItem";
import { SELECTOR_USER } from "../redux/selectors/auth";
import { useSelector, useDispatch } from "react-redux";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import InputMain from "./commons/Input-main";
import { Formik } from "formik";
import ButtomMain from "./commons/button-main";
import { BlueSecondBorderInputs } from "../utils/theme";
import {
  creditCardsService,
  subscriptionsService,
  userSubscriptionService,
  userService,
} from "../api/feathers";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import SelectMain from "./commons/Select-main";
import SnackBar from "./commons/snackbar";
import Backdrop from "./commons/Backdrop";
import { getAuth } from "../redux/actions/auth";
import AlertTitle from "../components/commons/Alert-title";

const useStyles = makeStyles(theme => ({
  root: { width: "100%" },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10,
    "& >div > h3": {
      color: BlueSecondLetters,
    },
  },
  rowBack: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 10,
    color: BlueMainLetters,
  },
  title: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: -20,
    color: BlueMainLetters,
    marginBottom: 30,
  },
  totalPayment: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "flex-start",
  },
  contendCreditCard: {
    display: "flex",
    width: "100%",
    "& #PaymentForm": {
      width: "100%",
    },
    "& .rccs": {
      width: "100%",
    },
    "& .rccs__card": {
      width: "100%",
    },
  },
  contendCard: {
    marginBottom: 30,
  },
  input: {
    marginTop: 23,
  },
}));

const DocumentsTypes = () => {
  return (
    <Fragment>
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      <MenuItem value={10}>Ten</MenuItem>
      <MenuItem value={20}>Twenty</MenuItem>
      <MenuItem value={30}>Thirty</MenuItem>
    </Fragment>
  );
};

const numberMask = createNumberMask({
  prefix: "-",
  thousandsSeparatorSymbol: "-",
  integerLimit: 4,
});

function CreditMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={numberMask}
      placeholderChar={"\u2000"}
    />
  );
}

function DateMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, "/", /\d/, /\d/]}
      placeholder="mm/dd"
    />
  );
}

function onlyLetter(e) {
  const keyCode = !e.charCode ? e.which : e.charCode;
  const regex = new RegExp("^[a-zA-Z ]+$");
  const key = String.fromCharCode(keyCode);
  if (keyCode !== 8 && !regex.test(key)) {
    e.preventDefault();
    return false;
  }
}

// function onlyNumbers(e) {
//   const keyCode = !e.charCode ? e.which : e.charCode;
//   const regex = new RegExp("^[0-9 ]+$");
//   const key = String.fromCharCode(keyCode);
//   if (keyCode !=== 8 && !regex.test(key)) {
//     e.preventDefault();
//     return false;
//   }
// }

function AddCreditCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(searchParams);
  const [subscription, setSubcription] = useState({});
  const [paymentStatus, setPaymentStatus] = useState();
  const [paymentErrorDescription, setPaymentErrorDescription] = useState();
  const [loading, setLoading] = useState(false);
  const [alertNotification, setAlertNotification] = useState({
    openAlert: false,
    message: "",
    severity: "success",
  });

  const user = useSelector(SELECTOR_USER);

  const [dataCreditCard, setDataCreditCard] = useState({
    cvv: "",
    expiry: "",
    focus: "",
    name: "",
    number: "",
    address: "",
    identification_number: "",
    type_document: "",
    phone: "",
  });

  const handleInputFocus = e => {
    setDataCreditCard({ ...dataCreditCard, focus: e.target.name });
  };

  const handleInputChange = e => {
    const { name, value } = e.target;

    setDataCreditCard({ ...dataCreditCard, [name]: value });
  };

  useEffect(() => {
    if (params.subscription)
      subscriptionsService.get(params.subscription).then(it => {
        setSubcription(it);
      });
  }, [params.subscription]);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div style={{ width: "100%", textAlign: "left", marginTop: -20 }}>
          {paymentStatus === "payment_rejected" && (
            <div>
              <AlertTitle
                severity="error"
                title="Pago rechazado."
                style={{ fontWeight: 700, fontSize: 16 }}
                iconSize={28}
                message={paymentErrorDescription}
              />
            </div>
          )}

          <h3>Añade tu tarjeta de crédito</h3>
        </div>
        <div className={classes.contendCreditCard}>
          <div id="PaymentForm">
            <div className={classes.contendCard}>
              <Cards
                cvv={dataCreditCard.cvv}
                expiry={dataCreditCard.expiry}
                focused={dataCreditCard.focus}
                name={dataCreditCard.name}
                number={dataCreditCard.number}
              />
            </div>
            <div className={classes.contendForm}>
              <Formik
                initialValues={dataCreditCard}
                validateOnChange={false}
                validateOnBlur={false}
                validate={values => {
                  const errors = {};
                  if (!dataCreditCard.name) {
                    errors.name = "Required";
                  }
                  if (!dataCreditCard.number) {
                    errors.number = "Required";
                  }
                  if (!dataCreditCard.expiry) {
                    errors.expiry = "Required";
                  }
                  if (!dataCreditCard.cvv) {
                    errors.cvv = "Required";
                  }
                  if (!dataCreditCard.identification_number) {
                    errors.identification_number = "Required";
                  }
                  if (!dataCreditCard.address) {
                    errors.address = "Required";
                  }
                  if (!dataCreditCard.type_document) {
                    errors.type_document = "Required";
                  }
                  if (!dataCreditCard.phone) {
                    errors.phone = "Required";
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setLoading(true);
                  const exp_month = dataCreditCard.expiry.split("/")[0];
                  const exp_year = dataCreditCard.expiry.split("/")[1];

                  creditCardsService
                    .create({
                      ...dataCreditCard,
                      owner_name: dataCreditCard.name,
                      masked_number: dataCreditCard.number,
                      exp_year,
                      exp_month,
                    })
                    .then(creditCard => {
                      console.log(creditCard)
                      setLoading(false);
                      props.getCreditCards();
                      props.setShowFrom(false);
                      /* if (params.subscription)
                        userSubscriptionService
                          .create({
                            subscription_id: subscription.id,
                            student_id: params.studentId,
                          })
                          .then(userSubcription => {
                            if (userSubcription.status === "payment_rejected") {
                              setPaymentStatus("payment_rejected");
                              setPaymentErrorDescription(
                                userSubcription.payment_data?.response?.response
                              );
                              window.scrollTo(0, 0);

                              const creditCardDefault = props.creditCards.find(
                                it => it.default === "true"
                              );

                              setLoading(false);
                              return;
                            }

                            userService.get(user.id).then(it => {
                              dispatch(getAuth(it));
                              history.push(
                                params.studentId
                                  ? `profile-student/${params.studentId}`
                                  : `/add-student?userSubscriptionId=${userSubcription.id}&newSubscription=true`
                              );
                              setLoading(false);
                            });
                          })
                          .catch(it => {
                            setLoading(false);
                            setAlertNotification({
                              openAlert: true,
                              message: it.message,
                              severity: "error",
                            });
                          });
                      else {
                        setLoading(false);
                        props.getCreditCards();
                        props.setShowFrom(false);
                      } */
                    })
                    .catch(it => {
                      setLoading(false);
                      setAlertNotification({
                        openAlert: true,
                        message: it.message,
                        severity: "error",
                      });
                    });
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className={classes.input}>
                      <InputMain
                        borderColor={BlueSecondBorderInputs}
                        name="name"
                        onChange={e => handleInputChange(e)}
                        onBlur={handleBlur}
                        value={dataCreditCard.name}
                        onFocus={e => handleInputFocus(e)}
                        label="Titular de la tarjeta"
                        onKeyDown={onlyLetter}
                        error={errors.name && touched.name}
                        autoComplete="off"
                      />
                    </div>
                    <div className={classes.input}>
                      <InputMain
                        inputProps={{
                          inputComponent: CreditMaskCustom,
                        }}
                        borderColor={BlueSecondBorderInputs}
                        name="number"
                        onChange={e => handleInputChange(e)}
                        onBlur={handleBlur}
                        // onKeyDown={onlyNumbers}
                        value={dataCreditCard.number}
                        onFocus={e => handleInputFocus(e)}
                        label="Numero de la tarjeta"
                        error={errors.number && touched.number}
                      />
                    </div>
                    <div className={classes.input}>
                      <InputMain
                        borderColor={BlueSecondBorderInputs}
                        name="expiry"
                        onChange={e => handleInputChange(e)}
                        onBlur={handleBlur}
                        value={dataCreditCard.expiry}
                        onFocus={e => handleInputFocus(e)}
                        label="Fecha de expiración"
                        InputProps={{
                          inputComponent: DateMaskCustom,
                        }}
                        error={errors.expiry && touched.expiry}
                      />
                    </div>
                    <div className={classes.input}>
                      <InputMain
                        borderColor={BlueSecondBorderInputs}
                        name="cvv"
                        onChange={e => handleInputChange(e)}
                        onBlur={handleBlur}
                        value={dataCreditCard.cvv}
                        onFocus={e => handleInputFocus(e)}
                        label="Código de seguridad"
                        inputProps={{
                          maxLength: 4,
                          type: "tel",
                        }}
                        autoComplete="off"
                        error={errors.cvv && touched.cvv}
                      />
                    </div>
                    <div className={classes.input}>
                      <SelectMain
                        borderColor={BlueSecondBorderInputs}
                        type="number"
                        name="type_document"
                        onChange={e => handleInputChange(e)}
                        onBlur={handleBlur}
                        value={dataCreditCard.type_document}
                        label="Tipo de documento"
                        children={<DocumentsTypes />}
                        error={errors.type_document && touched.type_document}
                        _data={[
                          { value: "CC", description: "CC" },
                          { value: "CE", description: "CE" },
                          { value: "NIT", description: "NIT" },
                          { value: "TI", description: "TI" },
                          { value: "DNI", description: "DNI" },
                        ]}
                      />
                    </div>
                    <div className={classes.input}>
                      <InputMain
                        borderColor={BlueSecondBorderInputs}
                        type="number"
                        name="identification_number"
                        onChange={e => handleInputChange(e)}
                        onBlur={handleBlur}
                        value={dataCreditCard.identification_number}
                        label="Numero de documento"
                        error={
                          errors.identification_number &&
                          touched.identification_number
                        }
                      />
                    </div>
                    <div className={classes.input}>
                      <InputMain
                        borderColor={BlueSecondBorderInputs}
                        name="address"
                        onChange={e => handleInputChange(e)}
                        onBlur={handleBlur}
                        value={dataCreditCard.address}
                        label="Dirección"
                        error={errors.address && touched.address}
                      />
                    </div>
                    <div className={classes.input}>
                      <InputMain
                        borderColor={BlueSecondBorderInputs}
                        name="phone"
                        onChange={e => handleInputChange(e)}
                        onBlur={handleBlur}
                        value={dataCreditCard.phone}
                        label="Celular"
                        error={errors.phone && touched.phone}
                      />
                    </div>
                    <div style={{ marginTop: 35 }}>
                      <ButtomMain
                        width="100%"
                        label={`Guardar tarjeta ${
                          params.subscription ? "y pagar" : ""
                        }`}
                        type="submit"
                        disabled={isSubmitting}
                      ></ButtomMain>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <SnackBar
          openAlert={alertNotification.openAlert}
          message={alertNotification.message}
          severity={alertNotification.severity}
          handleClose={() => setAlertNotification({ openAlert: false })}
        />
        <Backdrop open={loading} />
      </div>
    </div>
  );
}

export default AddCreditCard;
