/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useLocation, useRouteMatch } from "react-router-dom";
import { SELECTOR_USER, SELECTOR_IS_AUTH } from "../../redux/selectors/auth";
import { retryLoginAndGetCurrentUser } from "../../redux/actions/auth";

function Guard({ children }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [load, setLoad] = useState(false);
  const session = useSelector(SELECTOR_USER);
  const isAuth = useSelector(SELECTOR_IS_AUTH);

  useEffect(() => {
    dispatch(retryLoginAndGetCurrentUser())
      .then((it) => {
        setLoad(true);
      })
      .catch();
  }, []);

  const routesSubscriptionsDetails = [
    "/subscriptions-details",
    "/checkout",
    "/update-user",
  ];

  const routeMach = useRouteMatch(routesSubscriptionsDetails.map((it) => it));

  let url = location.pathname;

  if (!isAuth && load) {
    url = "/";
  } else if (isAuth && load) {
    if (session?.subscriptions && session?.subscriptions.length && routeMach) {
      url = "/checkout";
    } else if (location.pathname === "/") {
      url = "/home";
    }
  }

  return url !== location.pathname && load ? <Redirect to={url} /> : children;
}

export default Guard;