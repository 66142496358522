import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {},
}));

export default function WalletIcon(props) {
  const classes = useStyles();

  return (
    <svg
      id="user_4_"
      data-name="user (4)"
      xmlns="http://www.w3.org/2000/svg"
      width="18.41"
      height="18.41"
      viewBox="0 0 18.41 18.41"
    >
      <g id="Grupo_70" data-name="Grupo 70" transform="translate(0)">
        <path
          id="Trazado_275"
          data-name="Trazado 275"
          d="M18.363,15.775a.719.719,0,0,0-1.408.293.751.751,0,0,1-.152.63.731.731,0,0,1-.575.273H2.183a.731.731,0,0,1-.575-.273.751.751,0,0,1-.152-.63,7.945,7.945,0,0,1,7.56-6.292q.095,0,.191,0t.191,0a7.911,7.911,0,0,1,6.389,3.512.719.719,0,1,0,1.195-.8A9.354,9.354,0,0,0,12.133,8.8a4.89,4.89,0,1,0-5.854,0A9.353,9.353,0,0,0,.047,15.775,2.185,2.185,0,0,0,2.183,18.41H16.228a2.185,2.185,0,0,0,2.136-2.635ZM5.753,4.89A3.452,3.452,0,1,1,9.38,8.338l-.175,0-.174,0A3.456,3.456,0,0,1,5.753,4.89Z"
          transform="translate(0)"
          fill="#00a9e0"
        />
      </g>
    </svg>
  );
}
