import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BlueMainLetters, ColorButtonMain } from '../../utils/theme';
import InputMain from '../../components/commons/Input-main';
import ButtomMain from '../../components/commons/button-main';
import {
  ClientFeathers,
  authenticationService,
  cmsService,
} from '../../api/feathers';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getAuth } from '../../redux/actions/auth';
import { useDispatch } from 'react-redux';
import SnackBar from '../../components/commons/snackbar';
import { useHistory } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Dialog from '../../components/commons/Dialog';
import Terms from '../../components/Terms';

const useStyles = makeStyles((theme) => ({
  root: { width: '100%', marginTop: 60 },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  contentForm: {
    width: 300,
  },
  contentLogo: {
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 140,
    width: 140,
    '& img': {
      width: ' 100%',
      objectFit: 'cover',
      objectPosition: 'center',
      overflow: 'hidden',
    },
  },
  title: {
    fontSize: 30,
    fontWeight: 'bold',
    color: BlueMainLetters,
    marginBottom: 34,
    marginTop: 30,
  },
  rowBack: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: -29,
    '& div': {
      marginLeft: 20,
    },
  },
  key: {
    color: ColorButtonMain,
    marginTop: -18,
  },
}));

function Login(props) {
  const [loginPhone, setLoginPhone] = useState(true);
  const [hasOtpCode, setHasOtpCode] = useState(false);
  const history = useHistory();
  const [timer, setTimer] = useState(0);
  const [error, setError] = useState(false);
  const [errorTerms, setErrorTerms] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);
  const [termsText, setTermsText] = useState();

  const [snackBar, setSnackBar] = useState({
    visible: false,
    message: '',
    severity: '',
  });

  const dispatch = useDispatch();
  const [otpData, setOtpData] = useState({
    prefix: '',
    email: '',
    phone: '',
    otp: null,
  });
  const [terms, setTerms] = useState(false);

  const classes = useStyles();

  const handleChangeEmail = (event) => {
    setOtpData((prevState) => ({ ...prevState, email: event.target.value }));
  };

  const handleChangePhone = (phone, countryCode) => {
    setOtpData((prevState) => ({
      ...prevState,
      phone: phone.substr(countryCode.dialCode.length),
      prefix: countryCode.dialCode,
    }));
  };

  const handleChangeOtp = (e) => {
    setOtpData((prevState) => ({
      ...prevState,
      otp: e?.target?.value || null,
    }));
  };

  const sendOTP = async () => {
    const { prefix, email, phone } = otpData;
    if (!terms) {
      setErrorTerms(true);
      setSnackBar({
        visible: true,
        message: 'Acepta los términos y condiciones',
        severity: 'error',
      });
      return;
    }

    try {
      if (terms && loginPhone) {
        await authenticationService
          .create({
            strategy: 'sms',
            phone: phone,
            phone_country_code: prefix,
          })
          .then((it) => {
            if (terms) {
              setTimer(30);
              setHasOtpCode(true);
            }
          })
          .catch((err) => {
            setSnackBar({
              visible: true,
              message: err?.message,
              severity: 'error',
            });
          });
      } else {
        await authenticationService
          .create({
            strategy: 'email',
            email: email,
          })
          .then((it) => setHasOtpCode(true))
          .catch((err) =>
            setSnackBar({
              visible: true,
              message: err?.message,
              severity: 'error',
            })
          );
      }
    } catch (e) {
      console.log(e, 'error');
    }
  };

  const singIn = () => {
    const { prefix, email, phone, otp } = otpData;
    try {
      if (loginPhone) {
        if (!otp)
          return setSnackBar({
            visible: true,
            message: 'Por favor ingrese el código',
            severity: 'error',
          });
        ClientFeathers.authenticate({
          strategy: 'sms',
          phone: phone,
          phone_country_code: prefix,
          otp_sms: otp,
        })
          .then((it) => {
            handleChangeOtp(0);
            dispatch(getAuth(it.user));
            console.log(it)
            history.push(
              it?.user?.status === 'active' ? '/home' : '/profile'
            );
          })
          .catch((e) => {
            setSnackBar({
              visible: true,
              message: 'Código incorrecto, intente de nuevo',
              severity: 'error',
            });
            setError(true);
          });
      } else {
        ClientFeathers.authenticate({
          strategy: 'email',
          email,
          otp_email: otp,
        })
          .then((it) => {
            dispatch(getAuth(it.user));
            history.push(
              it.user.subscription?.length ? '/home' : '/home'
            );
          })
          .catch((e) => {
            setSnackBar({
              visible: true,
              message: 'Codigo incorrecto, intente de nuevo',
              severity: 'error',
            });
            handleChangeOtp(0);
            setError(true);
          });
      }
    } catch (e) {
      setError(true);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      timer > 0 && setTimer(timer - 1);
    }, 1000);
    if (timer === 0) {
      clearTimeout(timeout);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [timer]);

  useEffect(() => {
    cmsService.find({ id: 1 }).then((it) => setTermsText(it.data[0].body));
  }, []);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.container}>
          {hasOtpCode && (
            <div className={classes.rowBack}>
              <div
                onClick={() => {
                  setHasOtpCode(false);
                  handleChangeOtp(0);
                  setError(false);
                }}
              >
                <ArrowBackIcon />
              </div>
            </div>
          )}
          <div className={classes.contentLogo}>
            <img src="https://watertownbaby.s3.us-east-1.amazonaws.com/static/images/logo-sign.png" />
          </div>
          {!hasOtpCode ? (
            <h1 className={classes.title}>Iniciar sesión</h1>
          ) : (
            <div>
              <h2>
                Intoduce tu código enviado al:
                <span className={classes.key}>
                  {loginPhone ? otpData.phone : otpData.email}
                </span>
              </h2>
            </div>
          )}
          {!hasOtpCode ? (
            <div className={classes.contentForm}>
              <div className={classes.form}>
                {loginPhone ? (
                  <>
                    <PhoneInput
                      specialLabel="Escribe tu telefono"
                      inputProps={{
                        name: 'phone',
                        required: true,
                        autoFocus: true,
                      }}
                      inputStyle={{
                        height: 38,
                        marginBottom: 6,
                        marginTop: 10,
                        minWidth: 220,
                        borderRadius: 50,
                        width: '100%',
                        border: `2px solid ${BlueMainLetters}`,
                        '&::placeholder': {
                          color: 'black',
                        },
                      }}
                      buttonStyle={{
                        borderTopLeftRadius: 20,
                        borderBottomLeftRadius: 20,
                      }}
                      country={'co'}
                      value={` ${otpData.prefix} ${otpData?.phone}`}
                      onChange={handleChangePhone}
                    />
                  </>
                ) : (
                  <InputMain
                    error={error}
                    type="text"
                    value={otpData.email}
                    onChange={(e) => handleChangeEmail(e)}
                    placeholder="Escribe tu E-mail"
                    label="Ingresa con tu E-mail"
                    autoComplete="off"
                  />
                )}
              </div>
              <div
                style={{
                  textAlign: 'initial',
                  marginTop: 10,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  onClick={() => {
                    setTerms(!terms);
                    setErrorTerms(false);
                  }}
                  checked={terms}
                  style={{ padding: 0 }}
                  icon={<RadioButtonUncheckedIcon style={{ fontSize: 20 }} />}
                  checkedIcon={
                    <CheckCircleIcon
                      style={{ color: ColorButtonMain, fontSize: 20 }}
                    />
                  }
                  name="terms"
                />
                <div
                  style={{ fontSize: 14, color: errorTerms ? 'red' : 'black' }}
                  onClick={() => setOpenTerms(true)}
                >
                  Aceptar términos y condiciones
                </div>
              </div>
              <div style={{ marginTop: 30 }}>
                <ButtomMain
                  label={`Enviar código`}
                  onClick={() => sendOTP()}
                  width={'100%'}
                />
              </div>
              <div
                style={{ marginTop: 10, cursor: 'pointer' }}
                onClick={() => setLoginPhone(!loginPhone)}
              >
                {loginPhone
                  ? 'o ingresa con tu e-mail'
                  : 'o ingresa con tu teléfono'}
              </div>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                maxWidth: 300,
              }}
            >
              {loginPhone ? (
                <div>
                  Ingresa el código que te enviamos al numero
                  {`+${otpData.prefix} ${otpData.phone} `}
                  por SMS
                </div>
              ) : (
                <div>
                  Ingresa el código que te enviamos al correo {otpData.email}
                </div>
              )}
              <div style={{ marginTop: 16 }}>
                <InputMain
                  error={error}
                  type="number"
                  value={otpData.otp}
                  onChange={(e) => handleChangeOtp(e)}
                  maxLength={4}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 4);
                  }}
                  required
                  autoComplete="off"
                  textAlign="center"
                />
              </div>
              <div style={{ marginTop: 20 }}>
                <ButtomMain
                  label={`Entrar`}
                  onClick={() => singIn()}
                  width={'100%'}
                />
              </div>
              {timer > 0 ? (
                <div style={{ marginTop: 16, cursor: 'pointer' }}>
                  Reenviar código en {timer}
                </div>
              ) : (
                <div
                  style={{ marginTop: 20, cursor: 'pointer' }}
                  onClick={() => sendOTP()}
                >
                  Reenviar código
                </div>
              )}
            </div>
          )}
        </div>
        {snackBar?.visible && (
          <SnackBar
            openAlert={snackBar?.visible}
            message={snackBar?.message || ''}
            severity={snackBar?.severity || 'success'}
            handleClose={() =>
              setSnackBar({ visible: false, message: '', severity: '' })
            }
          />
        )}
        <Dialog
          open={openTerms}
          handleClose={() => setOpenTerms(false)}
          scrollType="paper"
          childrens={
            <Terms
              handleClose={() => setOpenTerms(false)}
              termsText={termsText}
            />
          }
        />
      </div>
    </>
  );
}

export default Login;
