export const BlueMainLetters = "#1B365D";
export const ColorButtonMain = "#00A9E0";
export const BlueSecondLetters = "#D4DADE";
export const BlueThirdBackground = "#C9EDF9";
export const BlueSecondBorderInputs = "#D1E5FB";
export const YellowBackground = "#F7ECC9";
export const YellowMain = "#F2A900";
export const RedBackground = "#FEC8C9";
export const RedMain = "#FF0000";
export const Green = "#97D700";
export const BackgroundColor = "#F9FDFF";
export const OrangeMain = "#F2A900";
