import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { ColorButtonMain } from "../../utils/theme";

const useStyles = makeStyles(theme => ({
  root: props => ({
    width: "100%",
    "& > button": {
      width: props.width ? props.width : "auto",
      borderRadius: 40,
      background: props.disabled ? "rgb(212, 218, 222)" : ColorButtonMain,
      paddingLeft: 16,
      paddingRight: 16,
      height: 40,
      fontWeight: "bold",
      color: "white",
      textTransform: "none",
    },
    "& .MuiButton-label": {
      fontSize: 17,
    },
    "& > button:hover": {
      paddingLeft: 16,
      paddingRight: 16,
      borderRadius: 40,
      background: ColorButtonMain,
      height: 40,
      fontWeight: "bold",
      color: "white",
    },
  }),
}));

export default function ButtonMain(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Button  {...props}>{props.label}</Button>
    </div>
  );
}
