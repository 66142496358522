import React from "react";
import moment from "moment";
import moment_es from "moment/locale/es";
import { Green } from "../utils/theme";
import CancelIcon from "@material-ui/icons/Cancel";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    textAlign: "left",
    width: "100%",
    "& > :first-child": {
      height: 25,
    },
  },
  items: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "left",
    margin: "14px 0px",
    "& > p": {
      margin: 0,
    },
  },
  itemsName: {
    marginLeft: 10,
    textTransform: "capitalize",
  },
  contentItem: {
    display: "flex",
    alignItems: "center",
    marginLeft: 15,
  },
  contentLine: {
    margin: "10px 0px",
    display: "fclex",
    width: "100%",
    justifyContent: "left",
  },
  line: {
    width: "2px",
    height: "100%",
    opacity: "0.5",
    backgroundColor: "#f2a900",
    margin: "0px 4px",
  },
});

export default function ScheduleClass(props) {
  const classes = useStyles();
  moment.locale("es", moment_es);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {props.studentSchedule.map(it => (
          <div className={classes.contentItem}>
            <div className={classes.items}>
              <div className={classes.line}></div>
              <div
                style={{
                  display: "flex",
                  marginLeft: -7,
                  alignItems: "center",
                }}
              >
                {it.status === "assisted" ? (
                  <CheckCircleIcon style={{ color: Green }} />
                ) : (
                  it.status === "not_assisted" && (
                    <CancelIcon style={{ color: "red" }} />
                  )
                )}
                <div className={classes.itemsName}>
                  {`Clase ${it.meta_course_name} ${moment(
                    it.start_date_time
                  ).format("dddd, D MMMM HH:mm")}`}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
