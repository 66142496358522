import styled, { css } from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10px;
`
export const TabContainer = styled.div`
    padding: 20px 0px 0px;
    height: 100%;
`
export const HeaderConfig = styled.div`
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: center;
    width: 100%;
    height:50px;
    padding-top: 15px;
`
export const DivButtonStep = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content:flex-start;
    align-items:center;
    ${({ lastChildren, theme, active }) => !lastChildren ? css`
    width: 19%;
    ::after {
        width: ${!lastChildren ? '78%' : ''};
        background: #e6e6e6;
        content: ' ';
        position:absolute;
        height:2px;
        left: 20%;
        transition: .5s;
    }
    :before {
        background: ${theme.PColor};
        width: ${active ? '78%' : '0%'};
        content: ' ';
        position:absolute;
        height:2px;
        left: 20%;
        transition: .8s;
        z-index: 1;
    }
    ` : css`
        width: max-content;
    `}
`
export const ButtonOption = styled.button`
    display: inline-flex;
    justify-content:center;
    position:relative;
    align-items:center;
    height:40px;
    width:40px;
    border:1px solid #d2d2d2;
    background: ${props => props.active ? '#1d84ee' : '#fff'};
    color: ${props => props.active ? '#ffffff' : '#000'};
    outline: none;
    border-radius: 50%;
    z-index: 1;
    transition: .3s;
    font-size: ${({ fontSize }) => fontSize || '14px'};
    font-family: PFont-Medium;

`
export const TitleSpan = styled.span`
    font-size: 14px;
    border-bottom: 1px solid #1d84ee;
    color: ${({ theme }) => theme.PBColor};
    border-radius:4px;
    text-align:center;
    width:${({ width }) => width ? width : '100%'};
    padding: 6px 8px;
`
export const DivButtons = styled.div`
    display:flex;
    justify-content:${({ justify }) => justify ? justify : 'center'};
    align-items:center;
    padding:0px 9px;
    width:auto;
`
export const ButtonGeneral = styled.button`
    width:auto;
    height:35px;
    display:flex;
    border:none;
    padding:0px 15px;
    border: 1px solid #d2d2d2;
    justify-content:space-between;
    align-items:center;
    flex-direction:row;
    font-size:15px;
    color: ${({ theme }) => theme.BGAColor};
    border-radius:4px;
    background: ${({ theme }) => theme.PColor};
    cursor: pointer;
    :hover{
        background:  #3290ef;
    }
`