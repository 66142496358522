import react, { useEffect } from "react";
import { s3Path } from "../utils/constants";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {},
  contentHeaderImage: {
    position: "absolute",
    zIndex: -20,
    right: 0,
    overflow: "hidden",
    top: -95,
    width: 130,
    "& > img": {
      height: "100%",
      objectFit: "cover",
      objectPosition: "center",
      opacity: 0.8,
    },
  },
  footer: {},
  contentFooterImage: {
    position: "fixed",
    bottom: -200,
    zIndex: -2,
    left: -200,
    overflow: "hidden",
    "& > img": {
      objectFit: "cover",
      objectPosition: "center",
      opacity: 0.5,
      width: 500,
    },
  },
});

function GreenGlobeCurve(props) {
  const classes = useStyles(props);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.contentHeaderImage}>
        <img
          src={`${window.imageShark(
            `static/images/backgrounds/blueGlobe.png`,
            200,
            200
          )}`}
          alt="img"
        />
      </div>
      {props.component}
      <div className={classes.contentFooterImage}>
        <img
          src={`${window.imageShark(
            `static/images/backgrounds/yellowGlobe.png`,
            200,
            200
          )}`}
          alt="img"
        />
      </div>
    </div>
  );
}

export default GreenGlobeCurve;
