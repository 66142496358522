const dayOfWeekFronInglesToSpanis = day => {
  let d = "";

  switch (day) {
    case "monday":
      d = "Lunes";
      break;
    case "tuesday":
      d = "Martes";
      break;
    case "wednesday":
      d = "Miercoles";
      break;
    case "thursday":
      d = "Jueves";
      break;
    case "friday":
      d = "Viernes";
      break;
    case "saturday":
      d = "Sabado";
      break;
    case "sunday":
      d = "Domingo";
      break;

    default:
      break;
  }

  return d;
};

export { dayOfWeekFronInglesToSpanis };
