import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default function StarIcom(props) {
  const classes = useStyles();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.779"
      height="22.47"
      viewBox="0 0 20.779 20.47"
    >
      <g id="favorites" transform="translate(0 -3.81)">
        <g id="Grupo_88" data-name="Grupo 88" transform="translate(1.2 3.81)">
          <g id="Grupo_87" data-name="Grupo 87" transform="translate(0 0)">
            <path
              id="Trazado_290"
              data-name="Trazado 290"
              d="M47.539,11.842A1.37,1.37,0,0,0,46.779,9.5l-4.547-.661a.274.274,0,0,1-.206-.15l-2.033-4.12a1.37,1.37,0,0,0-2.458,0L35.5,8.694a.274.274,0,0,1-.206.15L30.749,9.5a1.37,1.37,0,0,0-.759,2.337l3.29,3.207a.274.274,0,0,1,.079.243l-.777,4.528a1.37,1.37,0,0,0,1.988,1.445l4.067-2.138a.274.274,0,0,1,.255,0l4.067,2.138a1.37,1.37,0,0,0,1.988-1.445l-.777-4.528a.274.274,0,0,1,.079-.243Zm-4.449,3.635.777,4.528a.274.274,0,0,1-.4.289L39.4,18.156a1.37,1.37,0,0,0-1.275,0L34.06,20.294a.274.274,0,0,1-.4-.289l.777-4.528a1.37,1.37,0,0,0-.394-1.213l-3.29-3.207a.274.274,0,0,1,.152-.467l4.547-.661a1.37,1.37,0,0,0,1.032-.75l2.033-4.12a.274.274,0,0,1,.492,0h0l2.033,4.12a1.37,1.37,0,0,0,1.032.75l4.547.661a.274.274,0,0,1,.152.467l-3.29,3.207A1.37,1.37,0,0,0,43.089,15.477Z"
              transform="translate(-29.574 -3.81)"
              fill="#1b365d"
            />
          </g>
        </g>
        <g
          id="Grupo_90"
          data-name="Grupo 90"
          transform="translate(15.341 4.469)"
        >
          <g id="Grupo_89" data-name="Grupo 89" transform="translate(0 0)">
            <path
              id="Trazado_291"
              data-name="Trazado 291"
              d="M379.453,20.15a.548.548,0,0,0-.766.121l-.582.8a.548.548,0,1,0,.887.644l.582-.8A.548.548,0,0,0,379.453,20.15Z"
              transform="translate(-378.001 -20.045)"
              fill="#1b365d"
            />
          </g>
        </g>
        <g
          id="Grupo_92"
          data-name="Grupo 92"
          transform="translate(3.758 4.466)"
        >
          <g id="Grupo_91" data-name="Grupo 91" transform="translate(0 0)">
            <path
              id="Trazado_292"
              data-name="Trazado 292"
              d="M94.163,20.993l-.582-.8a.548.548,0,0,0-.887.644l.582.8a.548.548,0,1,0,.887-.644Z"
              transform="translate(-92.59 -19.966)"
              fill="#1b365d"
            />
          </g>
        </g>
        <g id="Grupo_94" data-name="Grupo 94" transform="translate(0 15.729)">
          <g id="Grupo_93" data-name="Grupo 93" transform="translate(0)">
            <path
              id="Trazado_293"
              data-name="Trazado 293"
              d="M2.011,297.872a.548.548,0,0,0-.691-.352l-.941.306a.548.548,0,1,0,.339,1.043l.941-.306A.548.548,0,0,0,2.011,297.872Z"
              transform="translate(0 -297.493)"
              fill="#1b365d"
            />
          </g>
        </g>
        <g
          id="Grupo_96"
          data-name="Grupo 96"
          transform="translate(9.842 22.194)"
        >
          <g id="Grupo_95" data-name="Grupo 95">
            <path
              id="Trazado_294"
              data-name="Trazado 294"
              d="M243.047,456.786a.548.548,0,0,0-.548.548v.99a.548.548,0,0,0,1.1,0v-.99A.548.548,0,0,0,243.047,456.786Z"
              transform="translate(-242.499 -456.786)"
              fill="#1b365d"
            />
          </g>
        </g>
        <g
          id="Grupo_98"
          data-name="Grupo 98"
          transform="translate(18.741 15.73)"
        >
          <g id="Grupo_97" data-name="Grupo 97">
            <path
              id="Trazado_295"
              data-name="Trazado 295"
              d="M463.442,297.846l-.941-.306a.548.548,0,0,0-.339,1.043l.941.306a.548.548,0,0,0,.339-1.043Z"
              transform="translate(-461.783 -297.513)"
              fill="#1b365d"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
