import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import FilterClass from '../../components/filter-class'
import Checkout from '../checkout'
import AddStudent from '../students/Add-student'
import ScheduleRegister from '../students/Pre-register'
import Susbcriptions from '../subscriptions/Subscriptions'

// Styles, Types and Utils
import { ButtonOption, Container, DivButtonStep, HeaderConfig, TabContainer } from './styled'

const step = [...Array(5)]

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const Steep = () => {
    const query = useQuery() 
    const [active, setActive] = useState('1')

    const scroll = useRef()

    // Effect query page render
    useEffect(() => {
        setActive(query.get('page') || '1')
        scroll.current?.scrollToTop()
    }, [])

    useEffect(() => {
        setActive(query.get('page'))
    }, [query])

    return (
        <Container>
            <HeaderConfig>
                {step.map((x, i) => (
                    <DivButtonStep lastChildren={i === step.length - 1} active={Number(active) > (i + 1)} key={`steps${i}`} >
                        <ButtonOption active={Number(active) >= (i + 1)} >{i + 1}</ButtonOption>
                    </DivButtonStep>
                ))}
            </HeaderConfig>
            <TabContainer>
                    {active === '1' && <AddStudent step={step} />}
                    {active === '2' && <FilterClass step={step} />}
                    {active === '3' && <Susbcriptions step={step} />}
                    {active === '4' && <ScheduleRegister step={step} /> }
                    {active === '5' && <Checkout step={step} /> }
            </TabContainer>
        </Container>
    )
}
