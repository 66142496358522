import { BrowserRouter, Switch, Route } from "react-router-dom";
import { commonsRoutes, loginRoutes } from "./routes/index";
import "./App.css";
import { Provider } from "react-redux";
import Guard from "./components/commons/Guard";
import "./utils/imageHandler";
import store from "./redux";

function App(props) {
  return (
    <div className="App">
      <Provider store={store()} onUpdate={() => window.scrollTo(0, 0)}>
        <BrowserRouter>
          <Guard>
            <Switch>
              {loginRoutes.map((route, idx) => (
                <Route path={route.path} exact={route.exact} key={idx}>
                  <route.layout component={route.component} />
                </Route>
              ))}
              {commonsRoutes.map((route, idx) => (
                <Route path={route.path} exact={route.exact} key={idx}>
                  <route.layout component={route.component} />
                </Route>
              ))}
            </Switch>
          </Guard>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
