import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import UserAvatar from "../components/commons/UserAvatar";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useDispatch, useSelector } from "react-redux";
import { logoutSession } from "../redux/actions/auth";
import { SELECTOR_USER } from "../redux/selectors/auth";
import { useHistory } from "react-router-dom";
import {
  BlueThirdBackground,
  ColorButtonMain,
  YellowBackground,
  YellowMain,
  RedBackground,
  RedMain,
} from "../utils/theme";

const useStyles = makeStyles(theme => ({
  root: { width: "100%", height: "100%" },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    paddingLeft: 10,
    paddingRight: 10,
    marginLeft: 15,
  },
  header: {
    paddingTop: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  menu: {
    width: "100%",
    "& > div": {
      display: "flex",
      marginBottom: 20,
      alignItems: "center",
    },
  },
  description: {
    marginLeft: 10,
  },
}));

function LateralMenu(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector(SELECTOR_USER);

  const logout = () => {
    dispatch(logoutSession());
  };

  const redirectTo = path => {
    history.push(path);
  };

  useEffect(() => {}, []);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.header}>
          <UserAvatar
            heigth={100}
            width={100}
            imgHeight={100}
            path={user.avatar_path}
          />
          <h4>
            {user.first_name} {user.last_name}
          </h4>
          <div className={classes.menu}>
            <div onClick={() => redirectTo("/profile")}>
              <div>
                <IconButton
                  style={{
                    borderRadius: 50,
                    backgroundColor: BlueThirdBackground,
                  }}
                >
                  <PersonOutlineOutlinedIcon
                    fontSize="medium"
                    style={{ color: ColorButtonMain }}
                  />
                </IconButton>
              </div>
              <div className={classes.description}>Perfil</div>
            </div>
            <div onClick={() => redirectTo("/payment-methods")}>
              <div>
                <IconButton
                  style={{
                    borderRadius: 50,
                    backgroundColor: YellowBackground,
                  }}
                >
                  <AccountBalanceWalletOutlinedIcon
                    fontSize="medium"
                    style={{ color: YellowMain }}
                  />
                </IconButton>
              </div>
              <div className={classes.description}>Metodos de pago</div>
            </div>
          </div>
        </div>
        <div className={classes.menu} onClick={() => logout()}>
          <div>
            <div>
              <IconButton
                style={{
                  borderRadius: 50,
                  backgroundColor: RedBackground,
                }}
              >
                <ExitToAppIcon fontSize="medium" style={{ color: RedMain }} />
              </IconButton>
            </div>
            <div className={classes.description}>Cerrar sesión</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LateralMenu;
