import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import S3Uploader from "react-s3-uploader";
import { HOST_API, PROTOCOL_HTTP } from "../../utils/constants";
import Backdrop from "../commons/Backdrop";

class S3Field extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    progress: null,
    image: null,
    onChange: null,
  };
  onSignedUrl = (...props) => {};

  onUploadProgress = (progress, ...props) => {
    this.setState({ progress, loading: true });
  };

  onUploadError = (error, progress) => {
    this.setState({ progress, loading: false });
  };

  onUploadFinish = urls => {
    let { onChange, id } = this.props;
    let image = urls.fileKey;

    this.setState({
      image,
      loading: false,
    });
    this.props.handleUploadFinish(image);
  };

  render() {
    let { file = {}, match, label, source, id } = this.props;
    const { image } = this.state;

    return (
      <Fragment>
        <label
          htmlFor={this.props.idComponent}
          className="flat-button-file"
          variant="outlined"
          color="primary"
        >
          {this.props.component}
        </label>
        <label style={{ display: "none" }}>
          <S3Uploader
            id={this.props.idComponent}
            signingUrl="/s3-files/sign"
            signingUrlMethod="GET"
            accept="image/*"
            s3path={this.props.path}
            preprocess={this.onUploadStart}
            onSignedUrl={this.onSignedUrl}
            onProgress={this.onUploadProgress}
            onError={this.onUploadError}
            onFinish={this.onUploadFinish}
            signingUrlWithCredentials={true} // in case when need to pass authentication credentials via CORS
            uploadRequestHeaders={{ "x-amz-acl": "public-read" }} // this is the default
            contentDisposition="auto"
            scrubFilename={filename => filename.replace(/[^\w\d_\-.]+/gi, "")}
            server={`${PROTOCOL_HTTP}://${HOST_API}`}
            // inputRef={(cmp) => (this.uploadInput = cmp)}
            autoUpload={true}
            className="s3-uploader"
            style={{ visibility: "hidden" }}
          />
        </label>
        <Backdrop open={this.state.loading} />
      </Fragment>
    );
  }
}

export default withRouter(S3Field);
