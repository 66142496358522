export const TIMEOUT = 60000;

export const HOST_API = "api.watertownswimschool.com";
// export const HOST_API = "localhost:3030";
export const PROTOCOL_HTTP = "https";
// export const PROTOCOL_HTTP = "http";
export const ACCESS_TOKEN = "feathers-jwt";
export const STORAGE = window.localStorage;
export const s3Path = "https://watertownbaby.s3.us-east-1.amazonaws.com";

export const HOST_FRONT = "https://mi.watertownswimschool.com";
export const s3PathImageHandrer = "https://drdpz3hp94vo0.cloudfront.net";

