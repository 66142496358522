import PoolIcon from '@material-ui/icons/Pool';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '../../components/commons/Accordion';
import ScheduleClass from '../../components/Schedule-class';
import { useHistory, useRouteMatch } from 'react-router-dom';
import UserAvatar from '../../components/commons/UserAvatar';
import ArrowBackIcon from '../../components/commons/BackIcon';
import AchievementList from '../../components/Achievements-list';
import { BlueMainLetters, BlueThirdBackground } from '../../utils/theme';
import CardSuspcription from '../../components/commons/CardSuspcription';
import {
  studentService,
  studentAchievementsService,
  studentScheduleService,
  userSubscriptionService,
} from '../../api/feathers';
import {
  StarIcon,
  AttenDanceHistoryIcon,
  WalletIcon,
  UserIcon,
} from '../../components/commons/icons';
import AttenDanceHistoryList from '../../components/Attendance-history-list';
import PaymentHistory from '../../components/Payment-history';
import AlertTitle from '../../components/commons/Alert-title';
import ButtomMain from '../../components/commons/button-main';
import S3Field from '../../components/commons/S3-field';
import FormEditStudent from '../../components/Form-edit-student';
import NoRecords from '../../components/commons/No-records';
import { Fragment } from 'react';
import CheckoutForms from '../checkout/CheckoutForms';

const useStyles = makeStyles((theme) => ({
  root: { width: '100%', marginBottom: 40 },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
  },
  rowBack: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
    color: BlueMainLetters,
  },
  contendAvatar: {
    display: 'flex',
    justifyContent: 'center',
  },
  nameStudent: {
    textTransform: 'capitalize',
    fontWeight: 'bold',
    marginTop: 8,
    color: BlueMainLetters,
  },
  contentCard: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    padding: '0px 20px',
    width: 320,
  },
  alertTitle: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'left',
    padding: '0px 10px',
    width: '100%',
  },
  contentActions: {
    padding: '0px 10px',
    marginTop: 14,
  },
  contentCardSubscription: {
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentCheckout: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 20,
  },
}));

function ProfileStudent(props) {
  const classes = useStyles();
  const history = useHistory();
  const [student, setStudent] = useState({});
  const [achievements, setAchiements] = useState([]);
  const [totalHistory, setTotalHistory] = useState(0);
  const [studentSchedule, setStudentSchedule] = useState([]);
  const [userSubscriptionHistory, setUserSubscriptionHistory] = useState([]);
  const [skip, setSkip] = useState(0);

  const match = useRouteMatch('/profile-student/:id');

  const getStudent = (id) => {
    studentService
      .find({ query: { id } })
      .then((it) => {
        setStudent(it.data[0]);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    Promise.all([
      getStudent(match.params.id),
      studentAchievementsService
        .find({ query: { student_id: match.params.id } })
        .then((it) => {
          setAchiements(it.data);
        })
        .catch((e) => console.log(e)),
      studentScheduleService
        .find({
          query: {
            status: { $in: ['assisted', 'not_assisted'] },
            $sort: { date: 1 },
            $limit: 50,
          },
        })
        .then((it) => {
          setStudentSchedule(it.data);
        }),
      fetchUserSubcriptionHistory(),
    ]);
  }, [props]);

  useEffect(() => {
    fetchUserSubcriptionHistory();
  }, [skip]);

  function addHistory(data) {
    if (skip) setUserSubscriptionHistory([...userSubscriptionHistory, ...data]);
    else setUserSubscriptionHistory([...data]);
  }

  const fetchUserSubcriptionHistory = () => {
    userSubscriptionService
      .find({ query: { $limit: 50, $skip: skip } })
      .then((it) => {
        setTotalHistory(it.total);
        addHistory(it.data);
      })
      .catch((e) => console.log(e));
  };

  const showMoreHistory = () => {
    setSkip(skip + 1);
    fetchUserSubcriptionHistory();
  };

  const handleUploadFinish = (url) => {
    studentService
      .patch(student.id, { avatar_path: url })
      .then((it) => setStudent(it));
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.rowBack} onClick={() => history.push('/home')}>
          <div>
            <ArrowBackIcon />
          </div>
        </div>
        <div className={classes.contendAvatar}>
          <UserAvatar
            heigth={100}
            width={100}
            imgHeight={100}
            path={student.avatar_path}
          />
        </div>
        <div className={classes.nameStudent}>
          {student.first_name} {student.last_name}
        </div>
        <div className={classes.contentCardSubscription}>
          {student.user_subscription ? (
            <div className={classes.contentCard}>
              <CardSuspcription
                subscription={{
                  image_cover: `${student.user_subscription?.subscription?.image_cover}`,
                  image: `${student.user_subscription?.subscription?.image}`,
                  max_courses_per_week: `${student.user_subscription?.subscription?.max_courses_per_week}`,
                  max_courses_per_month: `${student.user_subscription?.subscription?.max_courses_per_month}`,
                  description: `${student.user_subscription?.subscription?.description}`,
                }}
              />
            </div>
          ) : (
            <div className={classes.contentAlert}>
              <div className={classes.alertTitle}>
                <AlertTitle
                  severity="warning"
                  title="Estudiante sin subscripción."
                  style={{ fontWeight: 700, fontSize: 18 }}
                  iconSize={36}
                />
              </div>
              <div>
                <div style={{ marginTop: 20 }}>
                  <ButtomMain
                    label={`Obtener subscripción`}
                    onClick={() =>
                      history.push(`/filter-subscription?studentId=${student.id}`)
                    }
                  ></ButtomMain>
                </div>
              </div>
            </div>
          )}
          {student.user_subscription &&
            student.user_subscription.status != 'active' && (
              <AlertTitle
                severity={
                  student.user_subscription.status === 'pending_payment'
                    ? 'error'
                    : 'warning'
                }
                title={
                  student.user_subscription.status === 'pending_payment'
                    ? 'El gateway esta verificando el pago. Una vez aprobado o rechazado se actualizará el estado de la subscripción.'
                    : student.user_subscription.status === 'payment_rejected'
                    ? 'Pago rechazado. volver a pagar.'
                    : student.user_subscription.status === 'expired'
                    ? 'Subcripción Expirada. Te invitamos a renovarla realizando el pago.'
                    : student.user_subscription.status === 'canceled'
                    ? 'Subscripción cancelada'
                    : ''
                }
                style={{ fontWeight: 700, fontSize: 14 }}
                iconSize={24}
                width="320px"
              />
            )}
        </div>
        {student.user_subscription?.status !== 'payment_rejected' && student.user_subscription?.status !== 'expired' ? (
          <Fragment>
            <div className={classes.contentActions}>
              <Accordion
                backgroundColor="#FCDDEC"
                color="red"
                icon={<PoolIcon />}
                title="Clases programadas"
                children={
                  student?.user_subscription?.status === 'active' ? (
                    <ScheduleClass student={student} />
                  ) : (
                    <>
                      <NoRecords
                        component={<PoolIcon color="red" />}
                        message="Cuando tu subscripción este activa podrás programar clases."
                      />
                    </>
                  )
                }
              />
            </div>
            <div className={classes.contentActions}>
              <Accordion
                backgroundColor="#CED4DC"
                icon={<StarIcon />}
                title="Logros"
                children={
                  achievements.length ? (
                    <AchievementList achievements={achievements} />
                  ) : (
                    <NoRecords
                      component={<StarIcon />}
                      message="Aún no tienes logros"
                    />
                  )
                }
              />
            </div>
            <div className={classes.contentActions}>
              <Accordion
                backgroundColor="#FCEDC9"
                icon={<AttenDanceHistoryIcon />}
                title="Historial de asistencia"
                children={
                  studentSchedule.length ? (
                    <AttenDanceHistoryList studentSchedule={studentSchedule} />
                  ) : (
                    <NoRecords
                      component={<AttenDanceHistoryIcon />}
                      message="Aún no tienes historial de asistencia"
                    />
                  )
                }
              />
            </div>
            <div className={classes.contentActions}>
              <Accordion
                backgroundColor="#E5F5BF"
                icon={<WalletIcon />}
                title="Historial de pagos"
                children={
                  <PaymentHistory
                    userSubscriptionHistory={userSubscriptionHistory}
                    showShowMore={
                      totalHistory != userSubscriptionHistory.length
                    }
                    showMoreHistory={showMoreHistory}
                    student={student}
                  />
                }
              />
            </div>
            <div className={classes.contentActions}>
              <Accordion
                backgroundColor={BlueThirdBackground}
                icon={<UserIcon />}
                title="Información personal"
                children={
                  student ? (
                    <FormEditStudent
                      student={student}
                      setStudent={setStudent}
                    />
                  ) : null
                }
              />
            </div>
          </Fragment>
        ) : (
          <div className={classes.contentCheckout}>
            <CheckoutForms
              student_id={student.id}
              subscription_id={student.user_subscription?.subscription_id}
              user_subscription_id={student.user_subscription?.id}
              updateSubscription={true}
              getStudent={getStudent}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ProfileStudent;
