import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import { Green, BlueMainLetters } from "../utils/theme";
import ButtonMain from "../components/commons/button-main";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  content: {
    width: "100%",
    minHeight: 600,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default function ScheduleClass(props) {
  const classes = useStyles();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(searchParams);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div>
          <CheckCircleOutlinedIcon style={{ color: Green, fontSize: 50 }} />
        </div>
        <div
          style={{
            color: BlueMainLetters,
            fontSize: 24,
            fontWeight: 600,
            maxWidth: 250,
          }}
        >
          Clases programadas exitosamente
        </div>
        <div style={{ position: "absolute", bottom: 30 }}>
          <Link to={`/profile-student/${params.studentId}`}>
            <div className={classes.buttonMain}>
              <ButtonMain label="Continuar" style={{ width: 200 }} />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
