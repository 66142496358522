import React, { useEffect, useState, Fragment } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { BlueMainLetters } from "../../utils/theme";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
  root: {
    "& *::-webkit-appearance": "none",
  },

  input: {
    "& .MuiOutlinedInput-input": {
      paddingTop: 15,
      paddingBottom: 15,
      textAlign: "initial",
    },

    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: (props) =>
        props.borderColor ? props.borderColor : BlueMainLetters,
      borderRadius: 30,
      borderWidth: 2,
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: BlueMainLetters,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: 2,
    },
    "&:hover .MuiInputLabel-outlined": {
      color: BlueMainLetters,
    },
    width: "100%",
    "& label": {
      fontWeight: "bold",
      fontSize: 17,
      color: BlueMainLetters,
    },
    "& input": {
      "&::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
      },
      paddingTop: 10,
      paddingBottom: 10,
      // color: "red",
    },
  },
  width: (props) => (props.width ? props.width : "100%"),
}));

function InputMain(props) {
  const classes = useStyles(props);
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  useEffect(() => {}, []);

  return (
    <div className={classes.root}>
      <TextField
        className={classes.input}
        {...props}
        variant="outlined"
        label={props.label}
        select
        InputLabelProps={{
          shrink: true,
        }}
      >
        {props._data.map((it) => (
          <MenuItem value={it.value}>{it.description}</MenuItem>
        ))}
      </TextField>
    </div>
  );
}

export default InputMain;
