import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
  root: props => ({
    padding: 2,
    width: props.width ? props.width : "100%",
    "& > div": {
      borderRadius: 30,
      alignItems: "center",
    },
    alignItems: "center",
  }),
  alertMain: {
    justifyContent: "center",
    "& .MuiAlert-icon": {
      fontSize: props => props.iconSize,
      padding: 0,
    },
    "& .MuiAlert-message": {
      marginTop: 6,
      padding: 0,
    },
  },
}));

export default function DescriptionAlerts(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Alert severity={props.severity} className={classes.alertMain}>
        <AlertTitle {...props}>{props.title}</AlertTitle>
        {props.message ? props.message : null}
      </Alert>
    </div>
  );
}
