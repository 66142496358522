import React from "react";
import Home from "../views/home";
import Login from "../views/login";
import Main from "../layouts/main";
import Checkout from "../views/checkout/";
import GraySmall from "../layouts/GraySmall";
import GrayLarge from "../layouts/GrayLarge";
import ProfileUser from "../views/user/Profile";
import PaymentMethods from "../views/payment-methods/";
import ProfileStudent from "../views/students/Profile";
import AddStudent from "../views/students/Add-student";
import GreenGlobeCurve from "../layouts/GreenGlobeCurve";
import UpdateUserData from "../views/user/Update-user-data";
import ScheduleCreate from "../views/students/Schedule-create";
import Susbcriptions from "../views/subscriptions/Subscriptions";
import GreenPinkGlobeCurve from "../layouts/GreenPinkGlobeCurve";
import BlueGlobeYellowGlobe from "../layouts/BlueGlobeYellowGlobe";
import GrayLargeScheduleClass from "../layouts/GrayLargeScheduleClass";
import BlueGloveBackGroundGray from "../layouts/BlueGloveBackgroundGray";
import ScheduleCreatedSuccess from "../components/Schedule-class-success";
import SusbcriptionDetails from "../views/subscriptions/SubscriptionDetails";
import ScheduleRegister from "../views/students/Pre-register";
import FilterClass from "../components/filter-class";
import { Steep } from "../views/Steep";

const commonsRoutes = [
  {
    path: ["/add-student"],
    component: <AddStudent />,
    exact: true,
    layout: GraySmall,
  },
  {
    path: ["/filter-subscription"],
    component: <FilterClass />,
    exact: true,
    layout: GraySmall,
  },
  {
    path: ["/home"],
    component: <Home />,
    exact: true,
    layout: GrayLarge,
  },
  {
    path: ["/checkout"],
    component: <Checkout />,
    exact: true,
    layout: GreenGlobeCurve,
  },
  {
    path: ["/subscriptions"],
    component: <Susbcriptions />,
    exact: true,
    layout: GreenGlobeCurve,
  },
  {
    path: ["/subscriptions-details/:id"],
    component: <SusbcriptionDetails />,
    exact: false,
    layout: BlueGloveBackGroundGray,
  },
  {
    path: ["/update-user"],
    component: <UpdateUserData />,
    exact: false,
    layout: GreenGlobeCurve,
  },
  {
    path: ["/profile-student/:id"],
    component: <ProfileStudent />,
    exact: false,
    layout: GreenPinkGlobeCurve,
  },
  {
    path: ["/pre-register-class"],
    component: <ScheduleRegister />,
    exact: false,
    layout: GrayLargeScheduleClass,
  },
  {
    path: ["/create-class"],
    component: <ScheduleCreate />,
    exact: false,
    layout: GrayLargeScheduleClass,
  },
  {
    path: ["/schedule-created"],
    component: <ScheduleCreatedSuccess />,
    exact: false,
    layout: BlueGlobeYellowGlobe,
  },
  {
    path: ["/profile"],
    component: <ProfileUser />,
    exact: false,
    layout: Main,
  },
  {
    path: ["/payment-methods"],
    component: <PaymentMethods />,
    exact: false,
    layout: GreenGlobeCurve,
  },
  {
    path: ["/steep"],
    component: <Steep />,
    exact: false,
    layout: GreenGlobeCurve,
  },
  {
    component: <div>not found</div>,
  },
];

const loginRoutes = [
  {
    path: ["/"],
    component: <Login />,
    exact: true,
    layout: Main,
  },
];

export { commonsRoutes, loginRoutes };
