import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "../../components/commons/BackIcon";
import { useHistory } from "react-router-dom";
import { BlueMainLetters } from "../../utils/theme";
import ListCreditCards from "../../components/commons/List-credit-cards";
import { creditCardsService } from "../../api/feathers";
import ButtomMain from "../../components/commons/button-main";
import AddCreditCard from "../../components/AddCreditCard";

const useStyles = makeStyles({
  root: { width: "100%" },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 30,
  },
  rowBack: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 10,
    color: BlueMainLetters,
    zIndex: 10,
  },
  title: {
    width: "100%",
    display: "flex",
    justifyContent: "left",
    marginTop: -20,
    color: BlueMainLetters,
    marginBottom: 30,
    zIndex: -10,
  },
  contentBackBottom: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 30,
    padding: "0px 30px 0px 30px",
  },
  content: {
    marginTop: 40,
    width: "100%",
  },
});

export default function PaymentMehotds(props) {
  const classes = useStyles(props);
  const history = useHistory();
  const [creditCards, setCredictCards] = useState([]);
  const [showFrom, setShowFrom] = useState(false);

  const getCreditCards = () => {
    creditCardsService.find({ query: { $limit: 50 } }).then(it => {
      setCredictCards(it.data);
      if (!it.data.length) setShowFrom(true);
    });
  };

  useEffect(() => {
    getCreditCards();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.rowBack} onClick={() => history.goBack()}>
            <ArrowBackIcon />
          </div>
          <div className={classes.title}>
            <h2>Métodos de pago</h2>
          </div>
          <div className={classes.content}>
            {!!showFrom ? (
              <AddCreditCard
                setShowFrom={setShowFrom}
                getCreditCards={getCreditCards}
              />
            ) : (
              <>
                <ListCreditCards
                  creditCards={creditCards}
                  getCreditCards={getCreditCards}
                />
                <div style={{ marginTop: 20, width: "100%", marginBottom: 20 }}>
                  <ButtomMain
                    onClick={() => setShowFrom(!showFrom)}
                    width="100%"
                    label={`Agregar tarjeta`}
                  ></ButtomMain>
                </div>
              </>
            )}
          </div>

          <div className={classes.contentBackBottom}>
            {!!showFrom && (
              <div
                onClick={() => setShowFrom(!showFrom)}
                style={{
                  height: 36,
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  border: `1px black solid`,
                  color: "black",
                  justifyContent: "center",
                  fontWeight: "500",
                  fontSize: 16,
                  borderRadius: 20,
                  margin: "0px 40px 0px 40px",
                }}
              >
                Volver
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
