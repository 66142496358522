import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SELECTOR_USER } from "../../redux/selectors/auth";
import { BlueMainLetters } from "../../utils/theme";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import CardSuspcription from "../../components/commons/CardSuspcription";
import { Link, useHistory, useLocation } from "react-router-dom";
import Drawer from "../../components/Drawer";
import LateralMenu from "../../components/LateralMenu";
import { subscriptionsService, studentService } from "../../api/feathers";
import ArrowBackIcon from "../../components/commons/BackIcon";
import _ from "lodash";
import Snackbars from "../../components/commons/snackbar";

const useStyles = makeStyles(theme => ({
  root: { width: "100%" },
  content: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  title: {
    color: BlueMainLetters,
    textTransform: "capitalize",
    marginTop: props => props.params.studentId && 0,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rowBack: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 10,
    color: BlueMainLetters,
  },
  contentSubscriptions: {
    marginTop: 30,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    "& > a ": {
      width: "100%",
    },
  },
}));

function Subcriptions(props) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const history = useHistory();
  const params = Object.fromEntries(searchParams);
  const classes = useStyles({ params });
  const [student, setStudent] = useState(null);

  const user = useSelector(SELECTOR_USER);

  const [alertNotification, setAlertNotification] = useState({
    openAlert: false,
    message: "",
    severity: "success",
  });

  const openDrawerClick = bool => {
    setOpenDrawer(bool);
  };

  const previousPage = props => {
    history.goBack();
  };

  const closeDrawer = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {
    subscriptionsService.find({query: {
      $client: {
        level_id: params.level,
        program_id : params.program,
        max_courses_per_week : params.frequency,
        subscriptionFilters: 'true'
      }
    }}).then(it => {
      setSubscriptions(it.data);
    })
    .catch(err =>{
      setAlertNotification({
        openAlert: true,
        message: err.message,
        severity: 'error',
      })
    })
  }, [])

  useEffect(() => {
    if (params.studentId) {
      studentService
        .get(params.studentId)
        .then(it => {
          setStudent(it);
        })
        .catch(it => console.log(it));
    }
  }, [params.studentId]);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {params.studentId ? (
          <>
            <div className={classes.rowBack}>
              <div onClick={() => previousPage(props)}>
                <ArrowBackIcon />
              </div>
              <div>
                <MenuIcon
                  fontSize="large"
                  style={{ color: BlueMainLetters }}
                  onClick={() => openDrawerClick(true)}
                />
              </div>
            </div>
            <div
              style={{
                alignItems: "flex-start",
                display: "flex",
                marginTop: -18,
              }}
            >
              <p>
                {`Suscríbe a `}
                <strong>{`${_.capitalize(student?.first_name)}`}</strong>
                {` en uno de nuestros programas`}
              </p>
            </div>
          </>
        ) : (
          <>
            <div className={classes.header}>
              <h1 className={classes.title}>Hola {user.first_name}</h1>
              <div>
                <MenuIcon
                  fontSize="large"
                  style={{ color: BlueMainLetters }}
                  onClick={() => openDrawerClick(true)}
                />
              </div>
            </div>
            <div
              style={{
                alignItems: "flex-start",
                display: "flex",
                marginTop: -30,
              }}
            >
              <p>Suscríbete a nuestros programas</p>
            </div>
          </>
        )}
        <div className={classes.contentSubscriptions}>
          <div className={classes.title}>
            <h3 style={{ color: "#D4DADE" }}>Programas de natación</h3>
          </div>
          {subscriptions.map(it => (
            <Link
              to={
              props.step ? 
                `/steep?page=4&id=${it.id}&studentId=${params.studentId}` 
                :
                params.studentId
                  ? `/pre-register-class/${it.id}?studentId=${params.studentId}`
                  : `/pre-register-class/${it.id}`
              }
            >
              <CardSuspcription subscription={it} />
            </Link>
          ))}
        </div>
        <Drawer open={openDrawer} closeDrawer={() => closeDrawer()}>
          <LateralMenu />
        </Drawer>
      </div>
      <Snackbars
            openAlert={alertNotification.openAlert}
            message={alertNotification.message}
            severity={alertNotification.severity}
            handleClose={() => setAlertNotification({ openAlert: false })}
        />
    </div>
  );
}

export default Subcriptions;
