import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { creditCardsService } from "../../api/feathers";
import Divider from "../../components/commons/Divider";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { BlueMainLetters } from "../../utils/theme";
import { s3Path } from "../../utils/constants";
const useStyles = makeStyles({
  root: props => ({
    width: "100%",
  }),
  contendCreditCardsList: {
    width: "100%",
    textAlign: "left",
  },
  contentCreditCard: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
});

export default function ListCreditCards(props) {
  const classes = useStyles(props);

  const updateCreditCardMain = id => {
    creditCardsService
      .patch(id, { default: "true" })
      .then(it => {
        props.getCreditCards();
      })
      .catch(error => console.log(error));
  };

  return (
    <div className={classes.root}>
      <div className={classes.contendCreditCardsList}>
        {!!props.creditCards.length && (
          <div
            style={{
              marginBottom: 20,
              fontWeight: "500",
              fontSize: 14,
              color: BlueMainLetters,
            }}
          >
            Selecciona una tarjeta de credito
          </div>
        )}
        {props.creditCards.map(it => (
          <div>
            <Divider
              size={1}
              width={"100%"}
              backgroundColor={"#ededed"}
              marginTop={5}
              marginBottom={5}
              style={{ marginLeft: 30 }}
            />
            <div
              className={classes.contendList}
              onClick={() => updateCreditCardMain(it.id)}
            >
              <div className={classes.contentCreditCard}>
                <div>
                  <div className={classes.contentImage}>
                    <img
                      src={`${s3Path}/static/logos-tarjetas-de-credito/${it.brand}.png`}
                      height={40}
                      width={60}
                    />
                  </div>
                </div>
                <div style={{ textAlign: "left" }}>
                  <div style={{ textTransform: "capitalize" }}>
                    {it.owner_name}
                  </div>
                  <div>{it.masked_number}</div>
                </div>
                <div>
                  {it.default === "true" ? (
                    <RadioButtonCheckedIcon
                      style={{ color: BlueMainLetters }}
                    />
                  ) : (
                    <div>
                      <RadioButtonUncheckedIcon
                        style={{ color: BlueMainLetters }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
