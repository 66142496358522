import React, { Fragment } from "react";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function TemporaryDrawer(props) {
  return (
    <Fragment>
      <DialogTitle id="scroll-dialog-title">Términos y condiciones</DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText
          id="scroll-dialog-description"
          //   ref={descriptionElementRef}
          tabIndex={-1}
        >
          {props.termsText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Fragment>
  );
}
