import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { BlueMainLetters } from "../../utils/theme";

const useStyles = makeStyles((theme) => ({
  root: {
    color: BlueMainLetters,
  },
}));

export default function ArrowBackIcon1(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ArrowBackIcon {...props} />
    </div>
  );
}
