import react, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {},
  contentHeaderImage: {
    position: 'absolute',
    top: '-100px',
    zIndex: -2000,
    '& > img': {
      height: 400,
      objectFit: 'cover',
      objectPosition: 'center',
      opacity: 0.5,
      width: '100%',
      transform: 'rotate(-100deg)',
    },
  },
  contentFooterImage: {
    position: 'absolute',
    bottom: -10,
    zIndex: -2,
    // right: -260,
    overflow: 'hidden',
    '& > img': {
      objectFit: 'cover',
      objectPosition: 'center',
      opacity: 0.4,
      width: 300,
    },
  },
});

function Main(props) {
  const classes = useStyles(props);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.contentHeaderImage}>
        <img
          src={`${window.imageShark(
            `static/images/backgrounds/blueGlobe.png`,
            200,
            200
          )}`}
          className={classes.img}
          alt="img"
        />
      </div>
      {props.component}
      <div className={classes.contentFooterImage}>
        <img
          className={classes.img}
          src={`${window.imageShark(
            `static/images/backgrounds/purpleGlobe1.png`,
            200,
            200
          )}`}
          alt="img"
        />
      </div>
    </div>
  );
}

export default Main;
