import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import StarShieldIcon from "./commons/icons/Star-shield-icon";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    textAlign: "left",
    width: "100%",
  },
  items: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "6px 0px",
    "& > p": {
      margin: 0,
    },
  },
  itemsName: {
    marginLeft: 20,
    textTransform: "capitalize",
  },
  contentItem: {
    display: "flex",
    alignItems: "center",
  },
});

export default function ScheduleClass(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {props.achievements.map((it) => (
          <div className={classes.contentItem}>
            <div className={classes.items}>
              <StarShieldIcon />
            </div>
            <div className={classes.itemsName}>{it.achievement.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
