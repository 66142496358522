import auth from "../reducers/auth";
import { retryLogin, logout } from "../../api/feathers";
import { ACCESS_TOKEN, STORAGE } from "../../utils/constants";

export function getAuth(user) {
  return async (dispatch) => {
    dispatch(auth.Creators.setLogin(user));
  };
}

export const retryLoginAndGetCurrentUser = () => {
  return async (dispatch) => {
    if (STORAGE.getItem(ACCESS_TOKEN)) {
      return retryLogin().then((it) => {
        dispatch(auth.Creators.setLogin(it.user));
      });
    } else {
      return Promise.resolve();
    }
  };
};

export const logoutSession = () => {
  return async () => {
    logout().then((it) => {
      window.location.href = "/";
    });
  };
};
