import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import { s3Path } from "../../utils/constants";

const useStyles = makeStyles({
  root: {
    borderRadius: 26,
    boxShadow: "none",
    marginBottom: 10,
    width: "100%",
    overflow: "hidden",
  },
  media: {
    height: 160,
  },
  description: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  contendImage: {
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 90,
    width: 160,
    "& img": {
      width: " 100%",
      objectFit: "cover",
      objectPosition: "center",
      overflow: "hidden",
    },
  },
});

export default function MediaCard(props) {
  const classes = useStyles();

  const { subscription } = props;

  return (
    <div className={classes.root}>
      <div>
        <CardMedia
          className={classes.media}
          image={`${window.imageShark(
            `${subscription.image_cover}`,
            200,
            200
          )}`}
          title="Contemplative Reptile"
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "-160px",
          width: "100%",
        }}
      >
        <div>
          <div className={classes.description}>
            <div>
              <div className={classes.contendImage}>
                <img
                  src={`${s3Path}/${subscription.image}`}
                  alt="subcription"
                />
              </div>
            </div>
            <div style={{ textAlign: "left" }}>
              <h4 style={{ marginBottom: 0 }}>
                {subscription.max_courses_per_week} clases
              </h4>
              <p style={{ marginTop: 0 }}>semanales</p>
            </div>
          </div>
          <div className={classes.description} style={{ marginTop: -6 }}>
            <p style={{ maxWidth: 160 }}>{subscription.description}</p>
            <div style={{ textAlign: "left" }}>
              <h4 style={{ marginBottom: 0 }}>
                {subscription.max_courses_per_month} clases
              </h4>
              <p style={{ marginTop: 0 }}>mensuales</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
