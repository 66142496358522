import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '../../components/commons/BackIcon';
import { useHistory, useLocation } from 'react-router-dom';
import {
  BlueMainLetters,
  BlueSecondLetters,
  ColorButtonMain,
  Green,
} from '../../utils/theme';
import { s3Path } from '../../utils/constants';
import UserAvatar from '../../components/commons/UserAvatar';
import SnackBar from '../../components/commons/snackbar';
import {
  studentService,
  courseScheduleService,
  studentScheduleService,
} from '../../api/feathers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ButtonMain from '../../components/commons/button-main';
import Accordion from '../../components/commons/Accordion';
import lodash from 'lodash';
import { dayOfWeekFronInglesToSpanis } from '../../utils/dayOfWeek';
import moment from 'moment';
import Information from '../../components/commons/Information';
import PoolIcon from '@material-ui/icons/Pool';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: { width: '100%' },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  contentTitle: {
    alignItems: 'center',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    '& > h3': {
      fontSize: 20,
      color: BlueSecondLetters,
      marginTop: 60,
      marginBottom: 6,
    },
    '& > div > p': {
      fontSize: 14,
      color: BlueMainLetters,
      marginTop: 10,
      marginBottom: 6,
    },
  },
  rowBack: {
    width: '100%',
    display: 'flex',
    justifyContent: 'left',
    color: BlueMainLetters,
    alignItems: 'center',
  },
  contendAvatar: {
    display: 'flex',
    alignItems: 'center',
  },
  nameStudent: {
    textTransform: 'capitalize',
    fontWeight: 'bold',
    marginLeft: 6,
    color: BlueMainLetters,
  },
  title: {
    color: BlueMainLetters,
    fontSize: 14,
    fontWeight: 'bold',
    marginLeft: 25,
    marginTop: 22,
    marginBottom: 10,
    width: '100%',
  },
  buttonMain: {
    marginTop: 30,
    marginBottom: 30,
  },
  contentAccordions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0px 14px',
    marginTop: 10,
  },
  accordion: {
    width: '100%',
    marginTop: 20,
  },
  information: {
    marginTop: 20,
    padding: '0px 20px',
  },
  notAvailable: {
    textDecoration: 'line-through',
  },
}));

function ScheduleCreate(props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [student, setStudent] = useState({});
  const [scheduleGroupPerDayWeek, setScheduleGroupPerDayWeek] = useState({});
  const [data, setData] = useState([]);
  const [alertNotification, setAlertNotification] = useState({
    openAlert: false,
    message: '',
    severity: 'success',
  });
  const [checked, setChecked] = useState([]);
  const [subscription, setSubsciption] = useState();
  const [currentStudenSchedule, setCurrentStudenSchedule] = useState([]);
  const [buttonState, setButtonState] = useState(false);
  const [initWeekDays, setInitWeekDays] = useState([]);

  const searchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(searchParams);

  const initSchedule = (studentSchedule) => {

    const checkeda = {};
    for (const key in studentSchedule) {
      const schedule = studentSchedule[key];
      checkeda[`${schedule.course_schedule_id}`] = true;
      setChecked(checkeda);
    }
  };

  const handleChange = (event) => {
    const countChecked = window.document.getElementsByClassName(`Mui-checked`);

    if (
      countChecked.length === subscription?.subscription.max_courses_per_week &&
      event.target.checked
    ) {
      setChecked({ ...checked, [`${event.target.name}`]: false });

      setAlertNotification({
        openAlert: true,
        message: `Tu susbcripción tiene un máximo de ${subscription?.subscription.max_courses_per_week} clases por semana.`,
        severity: 'error',
      });

      return;
    }

    const exist = data.includes(parseInt(event.target.name));

    if (exist) {
      setData(data.filter((it) => it !== parseInt(event.target.name)));
    } else {
      const oldData = [...data];

      oldData.push(parseInt(event.target.name));

      setData(oldData);
    }

    setChecked({
      ...checked,
      [`${event.target.name}`]: event.target.checked,
    });
  };

  const activateButton = () => {
    const countChecked = window.document.getElementsByClassName(`Mui-checked`);
    if (
      countChecked.length === subscription?.subscription.max_courses_per_week
    ) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  };

  useEffect(() => {
    activateButton();
  }, [checked]);

  useEffect(() => {
    Promise.all([
      studentService
        .find({ query: { id: params.studentId, $limit: 50 } })
        .then((it) => {
          setStudent(it.data[0]);
          setSubsciption(it.data[0]?.user_subscription);
          courseScheduleService
            .find({
              query: {
                $client: {
                  availableStudent: 'true',
                  student_id: it.data[0].id,
                },
                $sort: { day_number_of_week: 1 },
                $limit: 3000,
              },
            })
            .then((it) => {
              setScheduleGroupPerDayWeek(lodash.groupBy(it.data, 'weekday'));
            });
        })
        .catch((e) => console.log(e)),
      studentScheduleService
        .find({ query: { student_id: params.studentId, $limit: 50 } })
        .then((it) => {
          //SETEA LOS IDS QUE TENGO ACTUALMENTE Y ELIMINA LOS IDS REPETIDOS
          setInitWeekDays(
            it?.data
              .map((it) => it.weekday)
              .filter(
                (v, i) => it?.data.map((it) => it.weekday).indexOf(v) === i
              )
          );
          setData(
            it?.data
              .map((it) => it.course_schedule_id)
              .filter(
                (v, i) =>
                  it?.data.map((it) => it.course_schedule_id).indexOf(v) === i
              )
          );

          setCurrentStudenSchedule(it.data);
        }),
    ]);
  }, [props]);

  useEffect(() => {
    if (student) initSchedule(currentStudenSchedule);
  }, [student, currentStudenSchedule]);

  const createSchedule = () => {
    studentScheduleService
      .create({ student_id: student.id, course_schedules_ids: data })
      .then((it) => {
        history.push(`/schedule-created?studentId=${student.id}`);
      })
      .catch((error) =>
        setAlertNotification({
          openAlert: true,
          message: error.message,
          severity: 'error',
        })
      );
  };
  const { max_courses_per_week } = subscription?.subscription
    ? subscription.subscription
    : 0;
  return (
    <div className={classes.root}>
      <div className={classes.rowBack} onClick={() => history.goBack()}>
        <div style={{ textAlign: 'left', marginTop: 10, marginLeft: 10 }}>
          <ArrowBackIcon />
        </div>
      </div>
      <div className={classes.header}>
        <div className={classes.contendAvatar}>
          <UserAvatar
            heigth={44}
            width={44}
            imgHeight={44}
            path={student.avatar_path}
          />
          <div className={classes.nameStudent}>
            {student.first_name} {student.last_name}
          </div>
        </div>
        {!!student.user_subscription && (
          <div className={classes.contentImg}>
            <img
              src={`${s3Path}/${student.user_subscription?.subscription.image}`}
              width={120}
            />
          </div>
        )}
      </div>
      <div className={classes.content}>
        <div className={classes.contentTitle}>
          <h3>Agendamiento de clases</h3>
          <div style={{ maxWidth: 330 }}>
            <p>
              Selecciona {max_courses_per_week} clases por semana para
              agendarlas y luego has clic en guardar.
            </p>
          </div>
        </div>
        <div className={classes.contentAccordions}>
          {Object.values(
            lodash.mapValues(scheduleGroupPerDayWeek, (values) => {
              return (
                <div className={classes.accordion}>
                  <Accordion
                    title={dayOfWeekFronInglesToSpanis(values[0].weekday)}
                    dayOfWeek={values[0].weekday}
                    titleFontWeigth="bold"
                    detailsMarginLeft={'10px'}
                    openWeekDays={initWeekDays}
                    schedule={true}
                    children={values.map((it) => {
                      const label = `De ${moment(
                        it.start_hour,
                        'HH:mm:ss'
                      ).format('hh:mm a')} a
                          ${moment(it.end_hour, 'HH:mm:ss').format('hh:mm a')}`;

                      const existAndMySchedule =
                        !it.available &&
                        !currentStudenSchedule
                          .map((it) => it.course_schedule_id)
                          .includes(it.id);
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={existAndMySchedule}
                              className={`check`}
                              icon={
                                <RadioButtonUncheckedIcon
                                  fontSize="small"
                                  style={{ color: ColorButtonMain }}
                                />
                              }
                              checkedIcon={
                                <RadioButtonCheckedIcon
                                  fontSize="small"
                                  style={{ color: ColorButtonMain }}
                                />
                              }
                              name={`${it.id}`}
                              checked={checked[`${it.id}`] ? true : false}
                              id={`${it.id}`}
                            />
                          }
                          className={clsx(
                            existAndMySchedule && classes.notAvailable
                          )}
                          onChange={(e) => handleChange(e)}
                          label={label}
                        />
                      );
                    })}
                  />
                </div>
              );
            })
          )}
        </div>
        <div className={classes.information}>
          <Information
            backgroundColor={
              data.length === max_courses_per_week
                ? 'rgb(151 215 0 / 11%)'
                : 'rgb(242 169 0 / 18%)'
            }
            color={data.length === max_courses_per_week ? Green : '#f2a900'}
            icon={<PoolIcon />}
            description={
              <div>
                Tienes
                <span style={{ fontWeight: 600 }}>{` ${data.length} `}</span>
                de
                <span style={{ fontWeight: 600 }}>
                  {` ${subscription?.subscription.max_courses_per_week} `}
                </span>
                clases escogidas
              </div>
            }
          />
        </div>
        <div className={classes.buttonMain}>
          <ButtonMain
            label="Agendar clases"
            style={{ width: 200 }}
            onClick={() => createSchedule()}
            disabled={!buttonState}
          />
        </div>
      </div>
      <SnackBar
        openAlert={alertNotification.openAlert}
        message={alertNotification.message}
        severity={alertNotification.severity}
        handleClose={() => setAlertNotification({ openAlert: false })}
      />
    </div>
  );
}

export default ScheduleCreate;
