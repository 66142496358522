import {FormControlLabel, FormLabel,Radio, RadioGroup} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { makeStyles, withTheme } from '@material-ui/styles';
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { levelService, programsService } from '../api/feathers';
import ButtonMain from './commons/button-main';
// import {iconDescipcion} from '../components/commons/icons/suscripcion.svg';
import StarSuscripcion from './commons/icons/suscripcion';
import figure from './commons/icons/figure.png';
import figureBottom from './commons/icons/figurebottom.png';
import figureleft from './commons/icons/figureleft.png'

const useStyles = makeStyles({
    Container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'flex-start',
        alignItems:'center',
        backgroundColor: '#F8FCFE',
        gap:30,
        minHeight:'100vh'
    },
    DivFilters: {
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
        margin: '0 auto',
        justifyContent: 'center',
        alignItems:'flex-start',
        borderRadius: '10px',
        gap:15,
        zIndex: 2,
    },
    divSection: {
        width: '100%',
        background: '#ffffffb3',
        borderRadius: 20,
        padding: '10px 0px',
    },
    Button: {
        width: '100%',
        display:'flex',
        justifyContent: 'center',
        alignItems:'center',
        marginTop: 30        
    },
    Img: {
        // color: (props) => (props.status === 'active' ? Green : 'red'),
        marginTop: 10,
    },
    SpanTitle: {
        width: '90%',
        textAlign: 'left',
        display: 'block',
        fontWeight: 'bold',
        color: '#1b365d',
        padding: '10px',
        fontSize: 15,
    },
    DivButtonFilter: {
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems: 'center',
        width: '100%',
        // border: '1px solid #ebebeb',
        height:'auto',
        borderRadius:6,
        cursor:'pointer',
        marginBottom: 16,
    },
    SectionFilter: {
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems: 'flex-start',
        width: '80%',
        gap:5,
        padding:'0px 10px',
        height:'100%'
    },
    SectionAloneFilter: {
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems: 'flex-start',
        width: '80%',
        padding:'0px 10px',
        height:'100%'
    },
    SectionRadio: {
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems: 'center',
        width: '20%',
        height:'100%'
    },
    SpanSection: {
        fontSize: 12,
        color: '#1B365D',
        fontWeight: 500,
    },
    SpanDescription: {
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        color: '#1B365D',
        "& svg": {
            // height: 50,
            marginRight: 10,
            // flex: 0.05,
        }
    },
    Figure1: {
        position: 'absolute',
        right: 0,
        top: '36%',
    },
    FigureBottom: {
        position: 'absolute',
        bottom: '90px',
        zIndex: '0',
    },
    Figure2: {
        position: 'absolute',
        left: 0,
        top: '50%'
    }
    }
);

export default function FilterClass(props) {
    //Custom state
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(searchParams);
    const classes = useStyles();
    const [levels, setLevels] = useState([])
    const [programs, setPrograms] = useState([])
    const [dataForm, setDataForm] = useState({
        level: '',
        program: '',
        frequency: ''
    })
    const [dataFrequency] = useState([
        { OptionValue: 1, opText: 'Vez a la semana' },
        { OptionValue: 2, opText: 'Veces a la semana'},
        { OptionValue: 3, opText: 'Veces a la semana'},
        { OptionValue: 4, opText: 'Veces a la semana'},
        { OptionValue: 5, opText: 'Veces a la semana'},
        // { OptionValue: 6, opText: 'Veces a la semana'},
        // { OptionValue: 7, opText: 'Veces a la semana'}
    ])

    useEffect(() => {
        levelService.find()
        .then(res => {
            setLevels(res?.data);
        }).catch(err => console.log(err))
        programsService.find()
        .then(res => {
            console.log(res)
            setPrograms(res?.data)
        }).catch(err => console.log(err))
    }, [])

    const handleChangeCheck = (event) => {
        setDataForm({ 
            ...dataForm,
            [event.target.name]: event.target.value 
        })
    };

/* console.log(dataForm) */
    return (
        <div className={classes.Container}>
            <img className={classes.FigureBottom} src={figureBottom}/>
            <img className={classes.Figure1} src={figure}/>
            <img className={classes.Figure2} src={figureleft}/>
            <div className={classes.Img}>{console.log(dataForm)}
                <img style={{mixBlendMode: "multiply"}} src='/logo.png' width={250} height={100} />
            </div>
            <h3 style={{margin: 0}}>Arma tu Subscripción</h3>
                {/* <span className={classes.SpanTitle}>Filtre los programas para seleccionar una subscripción</span> */}
            <div className={classes.DivFilters}>
            <div className={classes.divSection}>
            <span className={classes.SpanTitle}>Programa</span>
                {programs.length && programs.map((x, i) => 
                <label htmlFor={`radio-program-${x?.id}`} className={classes.DivButtonFilter} key={i} >
                    <div className={classes.SectionFilter}>
                        <span className={classes.SpanSection}>
                            {(x.name).toUpperCase()}
                        </span>
                        <span className={classes.SpanDescription}>
                        <div style={{flex: "0.05"}}>
                            <StarSuscripcion/>
                            </div>
                            <div style={{flex: "0.95"}}>
                            <p>{x.description}</p>
                            </div>
                        </span>
                    </div>
                    <div className={classes.SectionRadio}>
                    <Radio
                    
                        checked={x?.id.toString() === dataForm.program}
                        onChange={handleChangeCheck}
                        value={x.id}  
                        name='program'
                        style={{color: '#00a9e0'}}
                        inputProps={{ id:`radio-program-${x?.id}`}}
                        />
                    </div>
                </label> )}
                </div>
                <div className={classes.divSection}>
                <span className={classes.SpanTitle}>Niveles</span>
                {levels.length && levels.map((x, i) => 
                <label htmlFor={`radio-level-${x?.id}`} className={classes.DivButtonFilter} key={i} >
                    <div className={classes.SectionFilter}>
                        <span className={classes.SpanSection}>
                            {(x.name).toUpperCase()}
                        </span>
                        <span className={classes.SpanDescription}>
                            <div style={{flex: "0.05"}}>
                            <StarSuscripcion/>
                            </div>
                            <div style={{flex: "0.95"}}>
                            <p>{x.description}</p>
                            </div>
                        </span>
                    </div>
                    <div className={classes.SectionRadio}>
                    <Radio
                        checked={x?.id.toString() === dataForm.level}
                        onChange={handleChangeCheck}
                        value={x.id}
                        style={{color: '#00a9e0'}}  
                        name='level'
                        inputProps={{id:`radio-level-${x?.id}`}}
                        />
                    </div>
                </label> )}
                </div>
                <div className={classes.divSection}>
                <span className={classes.SpanTitle}>Frecuencia por semana</span>
                {dataFrequency.length && dataFrequency.map((x, i) => 
                <label htmlFor={`radio-frequency-${x?.OptionValue}`} /* onClick={() => handleChangeCheck({ target: { name: 'frequency', value: x?.OptionValue?.toString() } })} */ className={classes.DivButtonFilter} key={i} >
                    <div className={classes.SectionAloneFilter}>
                        <span className={classes.SpanSection}>
                            {(`${x?.OptionValue} - ${x?.opText}`).toUpperCase()}
                        </span>
                    </div>
                    <div className={classes.SectionRadio}>
                    <Radio
                        checked={x?.OptionValue.toString() === dataForm.frequency}
                        onChange={handleChangeCheck}
                        value={x?.OptionValue}  
                        name='frequency'
                        inputProps={{ id:`radio-frequency-${x?.OptionValue}` }}
                        style={{color: '#00a9e0'}}
                        />
                    </div>
                </label> )}
                </div>

                <div className={classes.Button}>
                    <Link to={ props.step ?  `/steep?page=3&studentId=${params.studentId}&level=${dataForm.level}&program=${dataForm.program}&frequency=${dataForm.frequency}`
                :    `/subscriptions?studentId=${params.studentId}&level=${dataForm.level}&program=${dataForm.program}&frequency=${dataForm.frequency}`
                }>
                        <div className={classes.buttonMain}>
                            <ButtonMain 
                                disabled={(!dataForm?.level || !dataForm?.program || !dataForm?.frequency )}
                                label="Filtrar Programas" 
                                style={{ width: 200 }} />
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}
