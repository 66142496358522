import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SELECTOR_USER } from '../../redux/selectors/auth';
import { BlueMainLetters, BlueSecondLetters } from '../../utils/theme';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '../../components/commons/BackIcon';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ButtomMain from '../../components/commons/button-main';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { subscriptionsService } from '../../api/feathers';
import { s3Path } from '../../utils/constants';
import numeral from 'numeral';
import ScheduleAvailiable from '../../components/Schedule-avaliable';

const useStyles = makeStyles((theme) => ({
  root: { width: '100%' },
  content: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  rowBack: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
    color: BlueMainLetters,
  },
  contentLogo: {
    marginTop: 30,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 180,
    width: 280,
    '& img': {
      width: ' 100%',
      objectFit: 'cover',
      objectPosition: 'center',
      overflow: 'hidden',
    },
  },
  contentItems: {
    display: 'flex',
    flexDirection: 'column',
    '& >div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& > p': {
      marginLeft: 10,
    },
  },
  colorIcon: {
    color: BlueSecondLetters,
  },
  contentDescription: {
    marginTop: 10,
  },
  contentSchedule: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '330px',
  },
  contentClass: {
    textAlign: 'left',
    marginTop: -30,
  },
}));

function SubcriptionsDetails(props) {
  const classes = useStyles();
  const match = useRouteMatch('/subscriptions-details/:id');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(searchParams);
  const user = useSelector(SELECTOR_USER);

  const [subscriptionDetail, setSubscriptionDetail] = useState({});

  const history = useHistory();
  const previousPage = (props) => {
    history.goBack();
  };

  const redirect = (subscriptionId) => {
    let url = null;
    if (
      params.studentId &&
      user.first_name &&
      user.last_name &&
      user.phone &&
      user.email
    ) {
      url = `/checkout?subscription=${subscriptionId}&studentId=${params.studentId}`;
    } else if (
      params.studentId &&
      (!user.first_name || !user.last_name || !user.phone || !user.email)
    ) {
      url = `/update-user?subscription=${subscriptionId}&studentId=${params.studentId}`;
    } else if (
      !params.studentId &&
      user.first_name &&
      user.last_name &&
      user.phone &&
      user.email
    ) {
      url = `/checkout?subscription=${subscriptionId}`;
    } else if (
      !params.studentId &&
      user.first_name &&
      user.last_name &&
      user.phone &&
      user.email
    ) {
      url = `/update-user?subscription=${subscriptionId}`;
    } else if (
      !params.studentId &&
      (user.first_name || user.last_name || user.phone || user.email)
    ) {
      url = `/update-user?subscription=${subscriptionId}`;
    }
    history.push(url);
  };

  useEffect(() => {
    subscriptionsService.get(match.params.id).then((it) => {
      setSubscriptionDetail(it);
    });
  }, [match?.params?.id]);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.rowBack}>
          <div onClick={() => previousPage(props)}>
            <ArrowBackIcon />
          </div>
        </div>
        <div className={classes.contentLogo}>
          <img src={`${s3Path}/${subscriptionDetail.image}`} />
        </div>
        <div className={classes.contentDescription}>
          <h1>
            {numeral(subscriptionDetail.price_with_tax).format('$0,0')}
            <span style={{ color: BlueSecondLetters, fontSize: 18 }}>
              / Mensual
            </span>
          </h1>
          <div>
            <p>{subscriptionDetail.description}</p>
          </div>
        </div>
        <div className={classes.contentItems}>
          <div>
            <CheckCircleIcon className={classes.colorIcon} />
            <p>{subscriptionDetail.max_courses_per_week} clases semanales</p>
          </div>
          <div>
            <CheckCircleIcon className={classes.colorIcon} />
            <p>{subscriptionDetail.max_courses_per_month} clases mensuales</p>
          </div>
        </div>
        <div className={classes.contentSchedule}>
          <h3>Horario disponible</h3>
          <div className={classes.contentClass}>
            <ScheduleAvailiable subscription_id={subscriptionDetail.id} />
          </div>
        </div>
        <div style={{ minWidth: 240, marginTop: 20 }}>
          <ButtomMain
            label={`Adquirir subscripción`}
            onClick={() => redirect(subscriptionDetail.id)}
          />
        </div>
      </div>
    </div>
  );
}

export default SubcriptionsDetails;
