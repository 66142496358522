import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "../../components/commons/BackIcon";
import { useHistory, useLocation } from "react-router-dom";
import { BlueMainLetters } from "../../utils/theme";
import { SELECTOR_USER } from "../../redux/selectors/auth";
import { useSelector } from "react-redux";
import "react-credit-cards/es/styles-compiled.css";
import InputMain from "../../components/commons/Input-main";
import { Formik } from "formik";
import ButtomMain from "../../components/commons/button-main";
import { BlueSecondBorderInputs } from "../../utils/theme";
import { userService } from "../../api/feathers";
import { useDispatch } from "react-redux";
import { getAuth } from "../../redux/actions/auth";
import SnackBar from "../../components/commons/snackbar";
import S3Field from "../../components/commons/S3-field";
import UserAvatar from "../../components/commons/UserAvatar";

const useStyles = makeStyles(theme => ({
  root: { width: "100%" },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingLeft: 20,
    paddingRight: 20,
  },
  rowBack: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 10,
    color: BlueMainLetters,
    zIndex: 10,
  },
  title: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: -20,
    color: BlueMainLetters,
    marginBottom: 30,
  },
  totalPayment: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "flex-start",
  },
  contendCreditCard: {
    display: "flex",
    width: "100%",
    "& #PaymentForm": {
      width: "100%",
    },
    "& .rccs": {
      width: "100%",
    },
    "& .rccs__card": {
      width: "100%",
    },
  },
  contendCard: {
    marginBottom: 30,
  },
  input: {
    marginTop: 23,
  },
  contendForm: {},
}));

function UpdateUserData(props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(searchParams);
  const user = useSelector(SELECTOR_USER);
  const [dataUser, setDataUser] = useState({});

  const [alertNotification, setAlertNotification] = useState({
    openAlert: false,
    message: "",
    severity: "success",
  });

  const handleInputChange = e => {
    const { name, value } = e.target;

    setDataUser({ ...dataUser, [name]: value });
  };

  const previousPage = props => {
    history.goBack();
  };

  useEffect(() => {
    setDataUser({
      first_name: user.first_name,
      last_name: user.last_name,
      phone: user.phone,
      email: user.email,
    });
  }, [user, props]);

  const handleUploadFinish = url => {
    userService
      .patch(user.id, { avatar_path: url })
      .then(it => {
        dispatch(getAuth(it));
      })
      .catch(it => {
        setAlertNotification({
          openAlert: true,
          message: it.message,
          severity: "error",
        });
      });
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.rowBack} onClick={() => previousPage(props)}>
          <div>
            <ArrowBackIcon />
          </div>
        </div>
        <div className={classes.title}>
          <h2>
            <span style={{ fontWeight: "normal" }}>Datos de </span>
            usuario
          </h2>
        </div>
        <div className={classes.totalPayment}>
          <h3>Completa tus datos</h3>
        </div>
        <div className={classes.contendAvatar}>
          <S3Field
            path={`user/logo/${user.id}`}
            handleUploadFinish={handleUploadFinish}
            id={"1"}
            label="Subir logo"
            idComponent="avatar"
            component={
              <UserAvatar
                heigth={100}
                width={100}
                imgHeight={100}
                path={user.avatar_path}
              />
            }
          />
        </div>
        <div className={classes.contendCreditCard}>
          <div id="PaymentForm">
            <div className={classes.contendForm}>
              <Formik
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={{}}
                validate={values => {
                  const errors = {};
                  if (!dataUser.first_name) {
                    errors.first_name = "Required";
                  }
                  if (!dataUser.last_name) {
                    errors.last_name = "Required";
                  }
                  if (!dataUser.email) {
                    errors.email = "Required";
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                      dataUser.email
                    )
                  ) {
                    errors.email = "Invalid email address";
                  }

                  if (!dataUser.phone) {
                    errors.phone = "Required";
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  userService
                    .patch(user.id, dataUser)
                    .then(it => {
                      dispatch(getAuth(it));
                      let url = null;
                      if (params.studentId) {
                        url = `/checkout?subscription=${params.subscription}?studentId=${params.studentId}`;
                      } else {
                        history.push(
                          `/checkout?subscription=${params.subscription}`
                        );
                      }
                    })
                    .catch(it => {
                      setAlertNotification({
                        openAlert: true,
                        message: it.message,
                        severity: "error",
                      });
                    });
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className={classes.input}>
                      <InputMain
                        borderColor={BlueSecondBorderInputs}
                        name="first_name"
                        onBlur={handleBlur}
                        onChange={e => handleInputChange(e)}
                        value={dataUser.first_name}
                        label="Nombres"
                        error={errors.first_name}
                      />
                    </div>
                    <div className={classes.input}>
                      <InputMain
                        borderColor={BlueSecondBorderInputs}
                        name="last_name"
                        onBlur={handleBlur}
                        onChange={e => handleInputChange(e)}
                        value={dataUser.last_name}
                        label="Apellidos"
                        error={errors.last_name}
                      />
                    </div>
                    <div className={classes.input}>
                      <InputMain
                        type="number"
                        borderColor={BlueSecondBorderInputs}
                        name="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={user.phone}
                        value={dataUser.phone}
                        label="Telefono"
                        error={errors.phone}
                      />
                    </div>
                    <div className={classes.input}>
                      <InputMain
                        borderColor={BlueSecondBorderInputs}
                        name="email"
                        onChange={e => handleInputChange(e)}
                        onBlur={handleBlur}
                        disabled={user.email}
                        value={dataUser.email}
                        label="Correo"
                        error={errors.email}
                      />
                    </div>
                    <div style={{ marginTop: 35 }}>
                      <ButtomMain
                        label={`Guardar`}
                        type="submit"
                        disabled={isSubmitting}
                      ></ButtomMain>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <SnackBar
          openAlert={alertNotification.openAlert}
          message={alertNotification.message}
          severity={alertNotification.severity}
          handleClose={() => setAlertNotification({ openAlert: false })}
        />
      </div>
    </div>
  );
}

export default UpdateUserData;
