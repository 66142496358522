import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {},
}));

export default function PendingPaymentIcon(props) {
  const classes = useStyles();

  return (
    <svg
      {...props}
      id="Capa_1"
      enable-background="new 0 0 512 512"
      height="30"
      viewBox="0 0 512 512"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="m466.949 211.243c8.284 0 15-6.716 15-15v-120.718c0-41.498-33.719-75.524-75.564-75.524-10.613 0-290.797-.001-301.351-.001-41.346 0-74.983 33.638-74.983 74.984v421.746c0 13.433 16.504 20.117 25.834 10.374l19.19-20.04 20.271 20.272c5.858 5.857 15.354 5.857 21.212.002l19.209-19.203 19.406 19.245c5.847 5.797 15.276 5.799 21.124.002l19.712-19.544 19.72 19.545c5.843 5.794 15.262 5.795 21.11.009l25.263-24.999c23.111 18.515 52.412 29.607 84.26 29.607 74.439 0 135-60.561 135-135 0-69.528-52.835-126.941-120.463-134.215v-31.542zm-15-135.718v105.718h-91.05v-105.718c0-25.103 20.422-45.525 45.525-45.525 25.102 0 45.525 20.422 45.525 45.525zm-225.652 400.094-19.73-19.555c-5.848-5.797-15.273-5.794-21.12.001l-19.708 19.541-19.45-19.289c-5.865-5.816-15.327-5.798-21.167.042l-19.165 19.159-20.507-20.506c-6.129-6.129-15.782-5.677-21.441.232l-3.958 4.133v-384.393c0-24.804 20.179-44.984 44.983-44.984h241.176c-9.602 12.668-15.311 28.44-15.311 45.525v167.366c-105.278 12.061-155.901 135.88-90.001 218.281zm225.065-98.619c0 57.897-47.103 105-105 105s-105-47.103-105-105 47.103-105 105-105 105 47.103 105 105z" />
        <path d="m336.362 335.362v30.638h-30.362c-8.284 0-15 6.716-15 15s6.716 15 15 15h45.362c8.284 0 15-6.716 15-15v-45.638c0-8.284-6.716-15-15-15s-15 6.716-15 15z" />
        <path d="m222.706 136.54c-14.351-11.184-33.076-12.92-37.258-20.47-1.476-2.665.097-7.054 3.3-9.208 4.558-3.065 14.309-1.857 17.735 1.303 6.09 5.618 15.58 5.233 21.196-.856 5.617-6.09 5.233-15.58-.856-21.196-4.66-4.298-10.571-7.365-16.961-9.155v-1.237c0-8.284-6.716-15-15-15s-15 6.716-15 15v2.241c-21.803 8.17-31.168 33.666-20.657 52.644 7.365 13.296 20.287 18.399 30.669 22.5 8.221 3.246 20.329 7.7 19.328 16.681-.478 4.282-3.716 8.531-8.059 10.574-6.194 2.914-18.648 1.113-26.126-3.779-6.932-4.534-16.23-2.591-20.764 4.341-4.535 6.933-2.591 16.229 4.341 20.764 5.828 3.813 13.28 6.888 21.268 8.6v1.908c0 8.284 6.716 15 15 15s15-6.716 15-15v-3.061c30.275-10.488 41.388-50.351 12.844-72.594z" />
        <path d="m180.999 256.08h-75c-8.284 0-15 6.716-15 15s6.716 15 15 15h75c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
        <path d="m180.999 316.18h-75.05c-8.284 0-15 6.716-15 15s6.716 15 15 15h75.05c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
        <path d="m180.999 376.08h-75.1c-8.284 0-15 6.716-15 15s6.716 15 15 15h75.1c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
      </g>
    </svg>
  );
}
