import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default function AttendanceHistoryIcon(props) {
  const classes = useStyles();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.546"
      height="22.674"
      viewBox="0 0 15.546 20.674"
    >
      <path
        id="audit"
        d="M13.623,1.615H10.578a2.423,2.423,0,0,0-4.569,0H2.923A2.425,2.425,0,0,0,.5,4.038V18.252a2.425,2.425,0,0,0,2.423,2.423h10.7a2.426,2.426,0,0,0,2.42-2.423V4.038A2.425,2.425,0,0,0,13.623,1.615ZM7.486,3.23V2.423a.808.808,0,1,1,1.615,0V3.23h1.615V4.805H5.87V3.23Zm6.945,15.021a.809.809,0,0,1-.806.808H2.923a.809.809,0,0,1-.808-.808V4.038a.809.809,0,0,1,.808-.808H4.255V6.42h8.076V3.23h1.292a.809.809,0,0,1,.808.808ZM11.37,9.289l1.195,1.087L7.393,16.059l-3.416-3.3L5.1,11.594l2.219,2.147Zm0,0"
        transform="translate(-0.5)"
        fill="#f2a900"
      />
    </svg>
  );
}
