import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";

const useStyles = makeStyles({
  list: {
    height: "100%",
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export default function TemporaryDrawer(props) {
  const classes = useStyles();

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={() => props.closeDrawer()}
      onKeyDown={() => props.closeDrawer()}
    >
      {props.children}
    </div>
  );

  return (
    <div>
      <Drawer
        className={classes.drawer}
        anchor="right"
        open={props.open}
        onBackdropClick={() => props.closeDrawer()}
      >
        {list("right")}
      </Drawer>
    </div>
  );
}
