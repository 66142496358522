import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  content: {
    display: "flex",
    maxHeight: 30,
    borderRadius: 20,
    textAlign: "center",
    fontSize: 13,
    justifyContent: "center",
    alignItems: "center",
    color: (props) => props.color,
    backgroundColor: (props) => props.backgroundColor,
    padding: 10,
  },
}));

function Information(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div>{props.icon}</div>
        <div>{props.description}</div>
      </div>
    </div>
  );
}

export default Information;
