import { s3PathImageHandrer } from "../utils/constants";

window.imageShark = (url, width, height) => {
  return `${s3PathImageHandrer}/${btoa(
    JSON.stringify({
      bucket: "watertownbaby",
      key: `${url}`,
      edits: { resize: { width, height, fit: "cover" } },
    })
  )}`;
};

window.imageSharkOriginSize = url =>
  btoa(
    JSON.stringify({
      bucket: "watertownbaby",
      key: `${s3PathImageHandrer}/${url}`,
    })
  );
