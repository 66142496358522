import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BlueSecondLetters } from "../../utils/theme";

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  message: props => ({
    fontSize: props.fontSize ? props.fontSize : 16,
    textAlign: "center",
    color: BlueSecondLetters,
    filter: "brightness(65%);",
    marginTop: 15,
    maxWidth: props.messageMaxWidth,
    lineHeight: 1.5,
  }),
});

export default function NotFound(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <div>{props.component} </div>
      <div className={classes.message}>{props.message} </div>
    </div>
  );
}
