import React from "react";
import Dialog from "@material-ui/core/Dialog";

export default function ScrollDialog(props) {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        scroll={props.scrollType}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        {props.childrens}
      </Dialog>
    </div>
  );
}
