import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BlueMainLetters, BlueSecondLetters } from '../utils/theme';
import { courseScheduleService } from '../api/feathers';
import Accordion from '../components/commons/Accordion';
import lodash from 'lodash';
import { dayOfWeekFronInglesToSpanis } from '../utils/dayOfWeek';
import moment from 'moment';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: { width: '100%' },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  contentTitle: {
    alignItems: 'center',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    '& > h3': {
      fontSize: 20,
      color: BlueSecondLetters,
      marginTop: 60,
      marginBottom: 6,
    },
    '& > div > p': {
      fontSize: 14,
      color: BlueMainLetters,
      marginTop: 10,
      marginBottom: 6,
    },
  },
  rowBack: {
    width: '100%',
    display: 'flex',
    justifyContent: 'left',
    color: BlueMainLetters,
    alignItems: 'center',
  },
  contendAvatar: {
    display: 'flex',
    alignItems: 'center',
  },
  nameStudent: {
    textTransform: 'capitalize',
    fontWeight: 'bold',
    marginLeft: 6,
    color: BlueMainLetters,
  },
  title: {
    color: BlueMainLetters,
    fontSize: 14,
    fontWeight: 'bold',
    marginLeft: 25,
    marginTop: 22,
    marginBottom: 10,
    width: '100%',
  },
  buttonMain: {
    marginTop: 30,
    marginBottom: 30,
  },
  contentAccordions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0px 14px',
    marginTop: 10,
  },
  accordion: {
    width: '100%',
    marginTop: 20,
  },
  information: {
    marginTop: 20,
    padding: '0px 20px',
  },
  notAvailable: {
    textDecoration: 'line-through',
  },
}));

function ScheduleAvailiable({ subscription_id }) {
  const classes = useStyles();
  const [scheduleGroupPerDayWeek, setScheduleGroupPerDayWeek] = useState({});
  const [initWeekDays, setInitWeekDays] = useState([]);

  useEffect(() => {
    if (subscription_id)
      courseScheduleService
        .find({
          query: {
            $limit: 10000,
            $client: {
              availableSubscription: 'true',
              subscription_id: subscription_id,
            },
            $sort: { day_number_of_week: 1 },
          },
        })
        .then((it) => {
          setScheduleGroupPerDayWeek(lodash.groupBy(it.data, 'weekday'));
        });
  }, [subscription_id]);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.contentAccordions}>
          {Object.values(
            lodash.mapValues(scheduleGroupPerDayWeek, (values) => {
              return (
                <div className={classes.accordion}>
                  <Accordion
                    title={`${dayOfWeekFronInglesToSpanis(values[0].weekday)}`}
                    dayOfWeek={values[0].weekday}
                    titleFontWeigth="bold"
                    detailsMarginLeft={'10px'}
                    openWeekDays={initWeekDays}
                    schedule={true}
                    children={values.map((it) => {
                      const label = `De ${moment(
                        it.start_hour,
                        'HH:mm:ss'
                      ).format('hh:mm a')} a
                          ${moment(it.end_hour, 'HH:mm:ss').format('hh:mm a')}`;

                      return (
                        <div
                          className={clsx(
                            !it.available && classes.notAvailable
                          )}
                        >
                          {label} - ( {it.total_quantity_available} cupos)
                        </div>
                      );
                    })}
                  />
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
}

export default ScheduleAvailiable;
