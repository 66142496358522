import react, { useEffect } from 'react';
import { s3Path } from '../utils/constants';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: { position: 'relative' },
  contentHeaderImage: {
    position: 'absolute',
    width: '100%',
    zIndex: -20,
    '& > img': {
      objectFit: 'cover',
      objectPosition: 'center',
      opacity: 0.8,
      width: '100%',
    },
  },
});

function GreenGlobeCurve(props) {
  const classes = useStyles(props);

  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log(props);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.contentHeaderImage}>
        <img
          src={`${window.imageShark(
            `static/images/backgrounds/BlueGloveBackgroundGray.png`,
            300,
            300
          )}`}
          alt="img"
        />
      </div>
      {props.component}
    </div>
  );
}

export default GreenGlobeCurve;
