import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default function StarSuscripcion(props) {
  const classes = useStyles();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="33" viewBox="0 0 12 33">
  <g id="Group_388" data-name="Group 388" transform="translate(-36 -248.5)">
    <line id="Line_11" data-name="Line 11" y2="32" transform="translate(42 249)" fill="none" stroke="#f2a900" stroke-linecap="round" stroke-width="1"/>
    <circle id="Ellipse_73" data-name="Ellipse 73" cx="6" cy="6" r="6" transform="translate(36 259)" fill="#f2a900"/>
  </g>
</svg>
  );
}
