import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BlueMainLetters } from "../../utils/theme";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
  root: {
    "& *::-webkit-appearance": "none",
    width: "100%",
  },
  input: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: props =>
        props.borderColor ? props.borderColor : BlueMainLetters,
      borderRadius: 30,
      borderWidth: 2,
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: BlueMainLetters,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: 2,
    },
    "&:hover .MuiInputLabel-outlined": {
      color: BlueMainLetters,
    },
    width: "100%",
    "& label": {
      fontWeight: "bold",
      fontSize: 17,
      color: props => (props.error ? "red" : BlueMainLetters),
    },
    "& input": {
      "&::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
      },
      paddingTop: 15,
      paddingBottom: 15,
      textAlign: props => (props.textAlign ? props.textAlign : "none"),
    },
  },
  width: props => (props.width ? props.width : "100%"),
}));

function InputMain(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <TextField
        {...props}
        className={classes.input}
        id="outlined-basic"
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
      />
    </div>
  );
}

export default InputMain;
