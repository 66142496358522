import react, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  contentHeaderImage: {
    position: 'absolute',
    width: '100%',
    zIndex: -20,
    '& > img': {
      height: 80,
      opacity: 0.8,
      width: '100%',
    },
  },
});

function GreenGlobeCurve(props) {
  const classes = useStyles(props);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.contentHeaderImage}>
        <img
          src={`${window.imageShark(
            `static/images/backgrounds/GraySmall.png`,
            500,
            200
          )}`}
          alt="img"
        />
      </div>
      {props.component}
    </div>
  );
}

export default GreenGlobeCurve;
