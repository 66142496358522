import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    boxShadow: "rgb(0 0 0 / 10%) 0px 7px 16px -6px",
    borderRadius: "16px",
    overflow: "hidden",
  },
  heading: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordion: {
    boxShadow: "none",
    backgroundColor: "rgba(255,255,255, 0.3)",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    marginLeft: props =>
      props.detailsMarginLeft ? props.detailsMarginLeft : 0,
  },
}));

export default function SimpleAccordion(props) {
  const classes = useStyles(props);

  const [expanded, setExpanded] = useState([]);

  const handleChange = dayOfWeek => (event, newExpanded) => {
    if (expanded.includes(dayOfWeek)) {
      setExpanded(expanded.filter(it => it !== dayOfWeek));
    } else setExpanded([...expanded, dayOfWeek]);
  };

  useEffect(() => {
    setExpanded(props.openWeekDays);
  }, [props.openWeekDays]);

  return (
    <div className={classes.root}>
      <Accordion
        className={classes.accordion}
        expanded={props.schedule && expanded.includes(props.dayOfWeek)}
        onChange={props.schedule && handleChange(props.dayOfWeek)}
      >
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            {props.icon && (
              <IconButton
                style={{
                  backgroundColor: props.backgroundColor,
                  color: props.color,
                }}
              >
                {props.icon}
              </IconButton>
            )}
            <div
              style={{
                marginLeft: 10,
                fontWeight: props.titleFontWeigth
                  ? props.titleFontWeigth
                  : "normal",
              }}
            >
              {props.title}
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          {props.children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
