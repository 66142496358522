import React from "react";
import { BlueSecondLetters } from "../utils/theme";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Green } from "../utils/theme";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
  },
  contentGroups: {
    minWidth: 80,
    width: "100%",
    overflow: "auto",
    display: "flex",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    paddingLeft: 0,
  },
  contentAvatar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "50%",
    padding: 4,
  },
  img: {
    height: 55,
    width: 55,
    objectFit: "cover",
    objectPosition: "center",
    borderRadius: "50px",
  },
  border: {
    border: `solid 3px ${BlueSecondLetters}`,
    borderRadius: "50%",
    height: 55,
    width: 55,
    overflow: "hidden",
  },
  contentAddStuden: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 4,
    minWidth: "120px !important",
  },
});

export default function AvatarGroups(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.contentGroups}>
        {[{ addStuden: true }, ...props.students].map((it, key) => (
          <Link
            to={`${it.addStuden ? "/add-student" : `profile-student/${it.id}`}`}
          >
            <div
              style={{
                minWidth: key === 0 ? "120px" : "80px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                className={
                  key === 0 ? classes.contentAddStuden : classes.contentAvatar
                }
              >
                <div
                  className={classes.border}
                  style={{
                    border:
                      key === 0
                        ? `solid 3px ${BlueSecondLetters}`
                        : `solid 3px  ${
                            it.user_subscription
                              ? it.user_subscription.status === "active"
                                ? Green
                                : "red"
                              : "red"
                          }`,
                    borderRadius: "50%",
                  }}
                >
                  <img
                    className={classes.img}
                    src={`${window.imageShark(
                      `${
                        it.avatar_path
                          ? it.avatar_path
                          : it.addStuden
                          ? "static/images/add-round.png"
                          : "static/images/background-avatar.png"
                      }`,
                      80,
                      80
                    )}`}
                    alt="logo"
                  />
                </div>
              </div>
              <div style={{ fontSize: 13, marginTop: -12 }}>
                <p>{key ? it.first_name : "Nuevo estudiante"}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
