import react, { useEffect } from 'react';
import { s3Path } from '../utils/constants';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  contentHeaderImage: {
    position: 'absolute',
    width: '100%',
    zIndex: -20,
    '& > img': {
      height: 180,
      width: '100%',
    },
  },
  contentGloveMedium: {
    position: 'absolute',
    marginTop: '400px',
    width: 140,
    zIndex: -2,
    overflow: 'hidden',
    right: 0,
    '& > img': {
      height: 280,
      objectFit: 'cover',
      objectPosition: 'center',
      opacity: 0.8,
    },
  },
  contentGloveBotton: {
    position: 'absolute',
    zIndex: -1,
    overflow: 'hidden',
    marginTop: -140,
    height: 160,
    left: -90,
    '& > img': {
      objectFit: 'cover',
      objectPosition: 'center',
      opacity: 0.5,
    },
  },
});

function GreenGlobeCurve(props) {
  const classes = useStyles(props);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.contentHeaderImage}>
        <img
          src={`${window.imageShark(
            `static/images/backgrounds/GraySmall.png`,
            300,
            100
          )}`}
          alt="img"
        />
      </div>
      <div className={classes.positionRelative}>
        <div className={classes.contentGloveMedium}>
          <img
            src={`${window.imageShark(
              `static/images/backgrounds/pinkBlueGlobe.png`,
              200,
              200
            )}`}
            alt="img"
          />
        </div>
      </div>
      {props.component}
      <div className={classes.positionRelative}>
        <div className={classes.contentGloveBotton}>
          <img
            src={`${window.imageShark(
              `static/images/backgrounds/greenGlobe.png`,
              200,
              200
            )}`}
            alt="img"
          />
        </div>
      </div>
    </div>
  );
}

export default GreenGlobeCurve;
