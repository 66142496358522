import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default function WalletIcon(props) {
  const classes = useStyles();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.74"
      height="20.739"
      viewBox="0 0 20.74 20.739"
    >
      <g id="credit-card" transform="translate(0 -0.011)">
        <g id="Grupo_132" data-name="Grupo 132" transform="translate(0 0.011)">
          <g id="Grupo_131" data-name="Grupo 131">
            <path
              id="Trazado_302"
              data-name="Trazado 302"
              d="M20.189,7.128,13.622.561a1.887,1.887,0,0,0-2.663,0L.55,10.97a1.887,1.887,0,0,0,0,2.662l.81.81h0L6.8,19.886H6.049a.432.432,0,0,0,0,.864H14.69a.432.432,0,1,0,0-.864h-4.6l10.1-10.1A1.885,1.885,0,0,0,20.189,7.128Zm-.611,2.051L9.168,19.589a1.011,1.011,0,0,1-.719.3h0a1.011,1.011,0,0,1-.718-.3L2.276,14.137l10-10a.432.432,0,1,0-.611-.611l-10,10-.5-.5a1.023,1.023,0,0,1,0-1.44L11.57,1.172a1.023,1.023,0,0,1,1.441,0l2.337,2.337-10,10a.432.432,0,0,0,.611.611l10-10,3.619,3.619A1.02,1.02,0,0,1,19.578,9.179Z"
              transform="translate(0 -0.011)"
              fill="#97d700"
            />
            <path
              id="Trazado_303"
              data-name="Trazado 303"
              d="M164.8,305.067a.432.432,0,0,0-.611,0l-3.4,3.4a.432.432,0,1,0,.611.611l3.4-3.4A.432.432,0,0,0,164.8,305.067Z"
              transform="translate(-154.15 -292.589)"
              fill="#97d700"
            />
            <path
              id="Trazado_304"
              data-name="Trazado 304"
              d="M192.347,384.743l-1.394,1.394a.432.432,0,1,0,.611.611l1.394-1.394a.432.432,0,0,0-.611-.611Z"
              transform="translate(-183.096 -369.037)"
              fill="#97d700"
            />
            <path
              id="Trazado_305"
              data-name="Trazado 305"
              d="M252.714,353.068l-.183.183a.432.432,0,1,0,.611.611l.183-.183a.432.432,0,0,0-.611-.611Z"
              transform="translate(-242.18 -338.645)"
              fill="#97d700"
            />
            <path
              id="Trazado_306"
              data-name="Trazado 306"
              d="M346.181,160.8a.432.432,0,0,0-.611,0l-1.833,1.833a.432.432,0,0,0,0,.611l1.222,1.222a.432.432,0,0,0,.611,0l1.833-1.833a.432.432,0,0,0,0-.611Zm-.917,2.75-.611-.611,1.222-1.222.611.611Z"
              transform="translate(-329.691 -154.161)"
              fill="#97d700"
            />
            <path
              id="Trazado_307"
              data-name="Trazado 307"
              d="M310.1,55.374a.43.43,0,0,0,.306-.127l.183-.183a.432.432,0,1,0-.611-.611l-.183.183a.432.432,0,0,0,.306.738Z"
              transform="translate(-297.129 -52.127)"
              fill="#97d700"
            />
            <path
              id="Trazado_308"
              data-name="Trazado 308"
              d="M99.876,355.059l-.183.183a.432.432,0,0,0,.611.611l.183-.183a.432.432,0,0,0-.611-.611Z"
              transform="translate(-95.533 -340.556)"
              fill="#97d700"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
