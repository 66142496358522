import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import Drawer from '../../components/Drawer';
import { CalendarIcon } from '../../components/commons/icons';
import { HamburguerIcon } from '../../components/commons/icons/index';
import moment from 'moment';
import LateralMenu from '../../components/LateralMenu';
import AvatarGroup from '../../components/Avatar-goup';
import Avatar from '../../components/commons/UserAvatar';
import { SELECTOR_USER } from '../../redux/selectors/auth';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { studentService, studentScheduleService } from '../../api/feathers';
import {
  BlueMainLetters,
  BlueSecondLetters,
  ColorButtonMain,
} from '../../utils/theme';
import NoRecords from '../../components/commons/No-records';
import FilterClass from '../../components/filter-class';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  title: {
    color: BlueMainLetters,
    textTransform: 'capitalize',
    fontSize: 40,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentNextClass: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
    background: 'rgba(255, 255, 255, 0.5)',
    borderRadius: '40px',
    minHeight: 260,
  },
  contendCard: {
    fontSize: 15,
    borderRadius: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
  contendHeader: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  description: {
    marginLeft: 10,
    alignItems: 'flex-start',
  },
  contentLine: {
    margin: '10px 0px',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  line: {
    width: '100%',
    height: '1px',
    opacity: '0.5',
    backgroundColor: ColorButtonMain,
    margin: '0px 4px',
  },
}));

function Home(props) {
  const classes = useStyles();
  const [weeks, setWeeks] = useState([]);
  const [students, setStudents] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [studentIds, setStudentsIds] = useState([]);
  const [studentSchedule, setStudentSchedule] = useState([]);

  const user = useSelector(SELECTOR_USER);

  const closeDrawer = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {
    studentService.find({ query: { $limit: 50 } }).then((it) => {
      setStudents(it.data);

      studentScheduleService
        .find({
          query: {
            date: { $gt: moment().format() },
            $sort: { start_date_time: 1 },
            status: { $nin: ['assisted', 'not_assisted'] },
            $limit: 50,
          },
        })
        .then((it) => {
          setStudentSchedule(it.data);
        });
    });
  }, []);

  useEffect(() => {
    const weeks = [];
    [0, 1, 2, 3].map((it) => {
      const currentDate = moment().add(it, 'weeks');
      weeks.push({
        start_week: currentDate.clone().startOf('isoWeek').format('DD'),
        end_week: currentDate.clone().endOf('isoWeek').format('DD MMM'),
        start_week_date: currentDate.clone().startOf('isoWeek').format(),
        end_week_date: currentDate.clone().endOf('isoWeek').format(''),
      });
      setWeeks(weeks);
    });
  }, []);

  return (
    // <FilterClass />
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.header}>
          <h1 className={classes.title}>
            <span style={{ fontWeight: '500' }}> Hola </span>
            {user.first_name}
          </h1>
          <div onClick={() => setOpenDrawer(true)}>
            <HamburguerIcon />
          </div>
        </div>
        <div
          style={{
            alignItems: 'flex-start',
            display: 'flex',
            marginTop: -40,
            fontSize: 14,
          }}
        >
          <p style={{
            width: '100%', 
            textAlign: 'center', 
            margin: '25px 0px'}}>
              Echa un vistazo a las próximas clases</p>
        </div>
        <div >
          <h3
            style={{
              fontSize: 20,
              color: BlueSecondLetters,
              marginBottom: 6,
              width:'100%',
              marginTop: 0
            }}
          >
            Estudiantes
          </h3>
        </div>
        <div>
          <AvatarGroup students={students} />
        </div>
        <div>
          <div className={classes.contentNextClass}>
            <div style={{ marginLeft: 10 }}>
              <h3>Próximas clases</h3>
            </div>
            <div className={classes.contendCard}>
              {studentSchedule.length ? (
                studentSchedule.map((it) => (
                  <>
                    <div className={classes.contendHeader}>
                      <div>
                        <Avatar
                          width={40}
                          imgHeight={40}
                          path={it.student.avatar_path}
                        />
                      </div>
                      <div className={classes.description}>
                        <div>
                          <p
                            style={{
                              color: 'rgb(170 175 178)',
                              fontSize: 15,
                              textTransform: 'capitalize',
                            }}
                          >
                            {`${it.student.first_name} / ${it.student.user_subscription?.name}`}
                          </p>
                        </div>
                        <div style={{ marginTop: -5 }}>
                          <p
                            style={{
                              textTransform: 'capitalize',
                              fontWeight: 'bold',
                            }}
                          >
                            {`${moment(it.start_date_time).format(
                              'dddd D MMMM'
                            )}`}
                          </p>
                          <p style={{ marginTop: -10 }}>
                            {`${moment(it.start_date_time).format('h:mm a')} a
                        ${moment(it.end_date_time).format('h:mm a')}`}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={classes.contentLine}>
                      <div className={classes.line}></div>
                    </div>
                  </>
                ))
              ) : (
                <div>
                  <NoRecords
                    component={
                      <CalendarIcon
                        color="#d4dade"
                        height="40px"
                        width="40px"
                      />
                    }
                    fontSize={20}
                    messageMaxWidth={'340px'}
                    message="Los estudiantes aún no tienen clases programadas."
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Drawer open={openDrawer} closeDrawer={() => closeDrawer()}>
        <LateralMenu />
      </Drawer>
    </div>
  );
}

export default Home;
