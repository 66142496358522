import { useSelector } from "react-redux";
import { s3Path } from "../../utils/constants";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  contentAvatar: (props) => ({
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: props.width,
    height: props.height,
    borderRadius: "50%",
  }),
  img: (props) => ({
    width: "100%",
    height: props.imgHeight,
    objectFit: "cover",
    objectPosition: "center",
  }),
});

function UserAvatar(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.contentAvatar}>
      <img
        className={classes.img}
        src={`${s3Path}/${
          props.path ? props.path : "static/images/background-avatar.png"
        } `}
        src={`${window.imageShark(
          `${props.path ? props.path : "static/images/background-avatar.png"}`,
          200,
          200
        )}`}
        alt="logo"
      />
    </div>
  );
}

export default UserAvatar;
