import React from "react";
import Numeral from "numeral";
import { ColorButtonMain, Green } from "../utils/theme";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  content: {
    width: "100%",
    fontSize: 15,
  },
  contentMembership: {
    borderRadius: 20,
    backgroundColor: "#EFFAFD",
    width: "100%",
    display: "flex",
    textAlign: "left",
    flexDirection: "column",
    marginBottom: 20,
  },
  title: {
    marginBottom: 10,
    marginTop: 5,
    fontWeight: "600",
    color: "#aaafb6",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    textAlign: "left",
  },
  contentHistory: {
    display: "flex",
    marginTop: 10,
    width: "100%",
    flexDirection: "column",
  },
  line: {
    width: "100%",
    height: "1px",
    opacity: "0.2",
    backgroundColor: ColorButtonMain,
    margin: "0px 4px",
  },
  contentLine: {
    margin: "20px 0px",
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  contentButton: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  button: {
    height: 40,
    width: 300,
    color: "#00A9E0",
    border: `3px solid #00A9E0`,
    borderRadius: 20,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
    fontSize: 16,
  },
  paid: {
    textAlign: "left",
    margin: 0,
  },
});

export default function PaymentHistory(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {props.userSubscriptionHistory
          .filter(
            it => it.status === "active" && it.student_id === props.student.id
          )
          .map(it => (
            <div className={classes.contentMembership}>
              <div style={{ padding: "10px 0px 10px 20px" }}>
                <div className={classes.title}>
                  <div>Membresia</div>
                </div>
                <div>
                  <div>
                    {`${it.name} por
                  ${Numeral(it.subscription?.price_with_tax).format(
                    "$0,0"
                  )}/mes`}
                  </div>
                  <div style={{ marginTop: 4 }}>
                    {`${it.subscription?.max_courses_per_week} Clases semanales - ${it.subscription?.max_courses_per_month} clases mensuales`}
                  </div>
                </div>
                <div className={classes.title} style={{ marginTop: 10 }}>
                  <div>Fecha de corte</div>
                </div>
                <div>
                  <div>
                    {moment(it.expire_date).format("D MMMM [del] YYYY")}
                  </div>
                </div>
              </div>
            </div>
          ))}

        <h3 className={classes.paid}>Pagos</h3>
        {props.userSubscriptionHistory
          .filter(
            it =>
              ["active", "pending_payment", "expired"].includes(it.status) &&
              it.student_id === props.student.id
          )
          .map(it => (
            <div className={classes.contentHistory}>
              <div className={classes.header}>
                <div style={{ color: "#aaafb6", fontWeight: "bold" }}>
                  {moment(it.createdAt).format("dddd D MMMM  hh:mm a")}
                </div>
                <div
                  style={{
                    color:
                      it.status === "expired"
                        ? "yellow"
                        : it.status === "active"
                        ? Green
                        : "red",
                    fontWeight: "bold",
                  }}
                >
                  {` ${Numeral(
                    it.payment_confirmations.filter(
                      itt => itt.user_subscription_id === it.id
                    )[0]?.value
                  ).format("$0,0")}`}
                </div>
              </div>
              <div style={{ textAlign: "left" }}>
                <div style={{ marginTop: 10 }}>
                  <div style={{ textTransform: "capitalize" }}>
                    {it.subscription?.name}
                  </div>
                  <div style={{ marginTop: 4 }}>
                    {`${moment(it.createdAt).format("DD/MM/YYYY")}`} -
                    {` ${moment(it.expire_date).format("DD/MM/YYYY")}`}
                  </div>
                  {/* <div style={{ marginTop: 4 }}>**** **** **** 5140</div> */}
                  {it.status === "pending_payment" ? (
                    <div
                      style={{ marginTop: 4, color: "red", fontWeight: "600" }}
                    >
                      PENDIENTE POR COBRO
                    </div>
                  ) : (
                    <div style={{ marginTop: 4 }}>
                      Cobrado:
                      {`${Numeral(
                        it.payment_confirmations.filter(
                          itt =>
                            itt.status === "Aceptada" &&
                            itt.user_subscription_id === it.id
                        )[0]?.value
                      ).format("$0,0")}`}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.contentLine}>
                <div className={classes.line}></div>
              </div>
            </div>
          ))}
        {props.showShowMore && (
          <div className={classes.contentButton}>
            <div
              className={classes.button}
              onClick={() => props.showMoreHistory()}
            >
              Cargar más
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
