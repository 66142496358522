import { Formik } from 'formik';
import { Green } from '../../utils/theme';
import { useHistory, useLocation } from 'react-router-dom';
import React, { useEffect, useState, Fragment } from 'react';
import {
  BlueMainLetters,
  BlueSecondBorderInputs,
  ColorButtonMain,
} from '../../utils/theme';
import { studentService, userSubscriptionService } from '../../api/feathers';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import S3Field from '../../components/commons/S3-field';
import SnackBar from '../../components/commons/snackbar';
import { SELECTOR_USER } from '../../redux/selectors/auth';
import InputMain from '../../components/commons/Input-main';
import UserAvatar from '../../components/commons/UserAvatar';
import ButtomMain from '../../components/commons/button-main';
import RadioGroup from '../../components/commons/Radio-group';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ArrowBackIcon from '../../components/commons/BackIcon';
import { PendingPaymentIcon } from '../../components/commons/icons';

import AlertTitle from '../../components/commons/Alert-title';

const useStyles = makeStyles((theme) => ({
  root: { width: '100%'},
  content: (props) => ({
    paddingLeft:
      props.params.payment && props.params.payment === 'success' ? 40 : 15,
    paddingRight:
      props.params.payment && props.params.payment === 'success' ? 40 : 15,
    marginBottom: 20,
    justifyContent: 'center',
  }),
  title: {
    color: BlueMainLetters,
  },
  input: {
    marginTop: 30,
  },
  gender: {
    marginTop: 5,
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contendAvatar: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  rowBack: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 10,
    color: BlueMainLetters,
  },
  contentCurrentSubscription: {
    width: '100%',
    margin: '20px 0px 20px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  labelCurrentSubscription: {
    color: BlueMainLetters,
    fontWeight: '600',
    marginRight: 10,
  },
}));

function AddStudent(props) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(searchParams);
  const classes = useStyles({ params, props });
  const history = useHistory();
  const user = useSelector(SELECTOR_USER);
  const [alertNotification, setAlertNotification] = useState(false);
  const [sub, setSub] = useState();
  const [userSubscription, setUserSubcription] = useState({});
  const [subscriptionsWhitOutStudent, setSubscriptionsWhitOutStudent] =
    useState([]);
  const [avatarPath, setAvatarPath] = useState(null);

  const handleUploadFinish = (url) => {
    setAvatarPath(url);
  };

  const previousPage = (props) => {
    history.goBack();
  };
  useEffect(() => {
    userSubscriptionService
      .get(params.userSubscriptionId)
      .then((it) => {
        setUserSubcription(it);
      })
      .catch((e) => console.log(e.message));
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {userSubscription && userSubscription.status === 'active' ? (
          <div className={classes.header}>
            <CheckCircleOutlineIcon style={{ color: Green, marginRight: 10 }} />
            <h3>Pago realizado con éxito</h3>
          </div>
        ) : userSubscription &&
          userSubscription.status === 'pending_payment' ? (
          <div className={classes.header}>
            <PendingPaymentIcon style={{ marginRight: 10, fill: 'red' }} />
            <h3>Pago pendiente</h3>
          </div>
        ) : (
          props.step ? null : <div className={classes.rowBack} onClick={() => previousPage(props)}>
            <ArrowBackIcon />
          </div>
        )}
        <div
          style={{
            color: BlueMainLetters,
            textAlign:
              params.payment && params.payment === 'success'
                ? 'left'
                : 'center',
            marginTop: 30,
          }}
        >
          <h3>Datos del estudiante</h3>
        </div>
        {params.newSubscription && params.newSubscription === 'true' && (
          <AlertTitle
            severity="info"
            title={
              userSubscription?.status && userSubscription?.status === 'active'
                ? 'Ahora solo debes agregar los datos del estudiante'
                : userSubscription?.status &&
                  userSubscription?.status === 'pending_payment'
                ? 'Agrega los datos del estudiante mientras aprobamos tu pago'
                : ''
            }
            style={{
              fontSize: 12,
              borderRadius: 30,
              color: ColorButtonMain,
            }}
            iconSize={24}
          />
        )}
        <div>
          <div className={classes.contendCreditCard}>
            <div id="PaymentForm">
              <div className={classes.contendForm}>
                <Formik
                  validateOnChange={false}
                  validateOnBlur={false}
                  initialValues={{
                    gender: null,
                    special_conditions: 'false',
                    subscription_id: sub,
                  }}
                  validate={(values) => {
                    const errors = {};

                    if (!values.first_name) {
                      errors.first_name = 'Required';
                    }
                    if (!values.last_name) {
                      errors.last_name = 'Required';
                    }
                    if (!values.gender) {
                      errors.gender = 'Required';
                    }
                    if (!values.birthday) {
                      errors.birthday = 'Required';
                    }
                    if (!values.special_conditions) {
                      errors.special_conditions = 'Required';
                    }
                    if (
                      values.special_conditions === 'true' &&
                      !values.special_conditions_description
                    ) {
                      errors.special_conditions = 'Required';
                    }

                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    studentService
                      .create({
                        ...values,
                        avatar_path: avatarPath,
                        user_subscription_id: userSubscription.id,
                      })
                      .then((it) => {
                        history.push(
                            props.step ? 
                          `steep?page=2&studentId=${it.id}`
                          :
                          params.newSubscription === 'true'
                            ? `profile-student/${it.id}`
                            : `filter-subscription?studentId=${it.id}`
                        );
                      })
                      .catch((it) => {
                        setAlertNotification({
                          openAlert: true,
                          message: it.message,
                          severity: 'error',
                        });
                      });
                    setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <form id='form' onSubmit={handleSubmit}>
                      {!!subscriptionsWhitOutStudent.length && (
                        <div className={classes.contentCurrentSubscription}>
                          <div className={classes.labelCurrentSubscription}>
                            Subscripción actual:
                          </div>
                          <div>{subscriptionsWhitOutStudent[0].name}</div>
                        </div>
                      )}
                      <div className={classes.input}>
                        <InputMain
                          borderColor={BlueSecondBorderInputs}
                          name="first_name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.first_name}
                          label="Nombres"
                          error={errors.first_name}
                        />
                      </div>
                      <div className={classes.input}>
                        <InputMain
                          borderColor={BlueSecondBorderInputs}
                          name="last_name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.last_name}
                          label="Apellidos"
                          error={errors.last_name}
                        />
                      </div>
                      <div className={classes.input}>
                        <InputMain
                          type="date"
                          borderColor={BlueSecondBorderInputs}
                          name="birthday"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.birthday}
                          label="Fecha de nacimiento"
                          error={errors.birthday}
                        />
                      </div>
                      <div className={classes.gender}>
                        <RadioGroup
                          label="Género"
                          data={[
                            { label: 'Masculino', value: 'masculine' },
                            { label: 'Femenino', value: 'female' },
                          ]}
                          name="gender"
                          value={values.gender}
                          onChange={handleChange}
                          error={errors.gender && touched.gender}
                          onBlur={handleBlur}
                          flexDirection="row"
                        />
                      </div>
                      <div className={classes.gender}>
                        <RadioGroup
                          label="Condiciones especiales"
                          data={[
                            { label: 'Si', value: 'true' },
                            { label: 'No', value: 'false' },
                          ]}
                          name="special_conditions"
                          value={values.special_conditions}
                          onChange={handleChange}
                          error={errors.special_conditions}
                          onBlur={handleBlur}
                          flexDirection="row"
                        />
                      </div>
                      {values.special_conditions &&
                        values.special_conditions === 'true' && (
                          <div>
                            <InputMain
                              borderColor={BlueSecondBorderInputs}
                              name="special_conditions_description"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.special_conditions_description}
                              multiline
                              rows={4}
                              error={errors.special_conditions_description}
                            />
                          </div>
                        )}
                      <div></div>
                      <div style={{ marginTop: 35 }}>
                        <ButtomMain
                          label={`Añadir estudiante`}
                          type="submit"
                          form='form'
                          disabled={isSubmitting}
                        ></ButtomMain>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <SnackBar
            openAlert={alertNotification.openAlert}
            message={alertNotification.message}
            severity={alertNotification.severity}
            handleClose={() => setAlertNotification({ openAlert: false })}
          />
        </div>
      </div>
    </div>
  );
}

export default AddStudent;
