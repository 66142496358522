import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import { BlueSecondBorderInputs, BlueMainLetters } from "../../utils/theme";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    textAlign: "start",
    "& .Mui-focused": {
      color: BlueMainLetters,
    },
  },
  radioGroup: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    color: BlueMainLetters,
    marginLeft: 25,
    marginRight: 25,
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
    "& > label": {
      color: BlueMainLetters,
      width: "100%",
      maxWidth: "130px",
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: props =>
        props.labelflexDirection ? props.labelflexDirection : "row-reverse",
      marginLeft: 0,
    },
  },
  label: {
    color: BlueMainLetters,
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 25,
    marginTop: 25,
    color: props => (props.error ? "red" : ""),
  },
}));

export default function RadioButtonsGroup(props) {
  const classes = useStyles(props);

  return (
    <FormControl component="fieldset" className={classes.root}>
      <FormLabel className={classes.label}>{props.label}</FormLabel>
      <RadioGroup {...props} className={classes.radioGroup}>
        {props.data.map((it, k) => (
          <FormControlLabel
            style={{ marginRight: 0 }}
            key={k}
            value={it.value}
            control={<Radio style={{ color: BlueSecondBorderInputs }} />}
            label={it.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
