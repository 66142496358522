import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {},
}));

export default function HamburguerIcon(props) {
  const classes = useStyles();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="19"
      viewBox="0 0 27 19"
    >
      <g id="Grupo_64" data-name="Grupo 64" transform="translate(1.5 1.5)">
        <path
          id="Trazado_272"
          data-name="Trazado 272"
          d="M19.134,0H0"
          transform="translate(4.866)"
          fill="none"
          stroke="#1b365d"
          stroke-linecap="round"
          stroke-width="3"
        />
        <line
          id="Línea_6"
          data-name="Línea 6"
          x1="24"
          transform="translate(0 8)"
          fill="none"
          stroke="#1b365d"
          stroke-linecap="round"
          stroke-width="3"
        />
        <line
          id="Línea_7"
          data-name="Línea 7"
          x1="19"
          transform="translate(5 16)"
          fill="none"
          stroke="#1b365d"
          stroke-linecap="round"
          stroke-width="3"
        />
      </g>
    </svg>
  );
}
