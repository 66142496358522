import React, { useEffect, useState, Fragment, useParams } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { BlueMainLetters } from '../../utils/theme';
import {
  creditCardsService,
  subscriptionsService,
  userSubscriptionService,
} from '../../api/feathers';
import Numeral from 'numeral';
import SnackBar from '../../components/commons/snackbar';
import Backdrop from '../../components/commons/Backdrop';
import AddCreditCard from '../../components/AddCreditCard';
import ButtomMain from '../../components/commons/button-main';
import ListCreditCards from '../../components/commons/List-credit-cards';

const useStyles = makeStyles((theme) => ({
  root: { width: '100%' },
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: -20,
    color: BlueMainLetters,
    '& h2 > span ': {
      fontWeight: 'normal',
    },
  },
  totalPayment: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    marginTop: 10,
  },
  contentButtonAdd: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 30,
  },
  contentButtons: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  buttonOutline: {
    height: 36,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    border: `1px black solid`,
    color: 'black',
    justifyContent: 'center',
    fontWeight: '500',
    fontSize: 16,
    borderRadius: 20,
    marginTop: 16,
  },
  infoPayment: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: -20,
    alignItems: 'center',
    '& > p': {
      marginBottom: 20,
      fontSize: 14,
      color: BlueMainLetters,
    },
  },
}));

const ButtonOutline = ({ setShowFrom, showFrom, description }) => {
  const classes = useStyles();

  return (
    <div
      onClick={() => setShowFrom(!showFrom)}
      className={classes.buttonOutline}
    >
      {description}
    </div>
  );
};

function CheckoutForms({
  subscription_id,
  student_id,
  updateSubscription,
  user_subscription_id,
  getStudent,
}) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [subscription, setSubcription] = useState({});
  const [creditCards, setCredictCards] = useState([]);
  const [showFrom, setShowFrom] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertNotification, setAlertNotification] = useState({
    openAlert: false,
    message: '',
    severity: 'success',
  });
  const searchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(searchParams);

  useEffect(() => {
    Promise.all([
      subscriptionsService
        .get(subscription_id)
        .then((it) => {
          setSubcription(it);
        })
        .catch((e) => console.log(e.message)),
      getCreditCards(),
    ]);
  }, []);

  const getCreditCards = () => {
    creditCardsService.find({ query: { $limit: 50 } }).then((it) => {
      setCredictCards(it.data);
      setShowFrom(!it.data.length ? true : false);
    });
  };

  const toPaid = () => {
    setLoading(true);
    userSubscriptionService
      .patch(params.userSubscriptionId, {}, { query: { $client: { activePay: 'true' } }})
      .then((it) => {
        setLoading(false);
        history.push(`/profile-student/${it.student_id}`);
      })
      .catch((it) => {
        setLoading(false);
        setAlertNotification({
          openAlert: true,
          message: it.message,
          severity: 'error',
        });
        setTimeout(() => {history.push(`/home`)}, 1000);
      })
  }
  
  // const toPaid = () => {
  //   setLoading(true);
  //   userSubscriptionService
  //     .create({
  //       subscription_id: subscription_id,
  //       student_id: student_id,
  //     })
  //     .then((it) => {
  //       setLoading(false);
  //       history.push(`/profile-student/${it.student_id}`);
  //     })
  //     .catch((it) => {
  //       setLoading(false);
  //       setAlertNotification({
  //         openAlert: true,
  //         message: it.message,
  //         severity: 'error',
  //       });
  //     });
  // };

  const toUpdateSubscription = () => {
    setLoading(true);
    userSubscriptionService
      .patch(user_subscription_id, {
        status: 'active',
      })
      .then((it) => {
        setLoading(false);
        getStudent();
      })
      .catch((it) => {
        setLoading(false);
        setAlertNotification({
          openAlert: true,
          message: it.message,
          severity: 'error',
        });
      });
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <h2>
          <span>Método de</span> pago
        </h2>
      </div>
      <div className={classes.totalPayment}>
        <h3>Total a pagar</h3>
        <div className={classes.infoPayment}>
          <p>Suscripción {subscription.name}</p>
          <h3>{Numeral(subscription.price_with_tax).format('$0,0')}</h3>
        </div>
      </div>
      {!!showFrom ? (
        <AddCreditCard
          creditCards={creditCards}
          getCreditCards={getCreditCards}
          setShowFrom={setShowFrom}
        />
      ) : (
        <ListCreditCards
          creditCards={creditCards}
          getCreditCards={getCreditCards}
        />
      )}
      <div className={classes.contentButtonAdd}>
        {!showFrom ? (
          <div className={classes.contentButtons}>
            {!!creditCards.length && (
              <>
                <ButtomMain
                  onClick={() =>
                   updateSubscription ? toUpdateSubscription() : toPaid()
                  }
                  width="100%"
                  label={`Pagar`}
                ></ButtomMain>
                <ButtonOutline
                  setShowFrom={setShowFrom}
                  showFrom={showFrom}
                  description={'Agregar Tarjeta'}
                />
              </>
            )}
          </div>
        ) : (
          !!creditCards.length && (
            <div
              onClick={() => setShowFrom(!showFrom)}
              className={classes.buttonOutline}
            >
              Volver
            </div>
          )
        )}
      </div>
      <SnackBar
        openAlert={alertNotification.openAlert}
        message={alertNotification.message}
        severity={alertNotification.severity}
        handleClose={() => setAlertNotification({ openAlert: false })}
      />
      <Backdrop open={loading} />
    </div>
  );
}

export default CheckoutForms;
