import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default function StarShield(props) {
  const classes = useStyles();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31.596"
      height="38.139"
      viewBox="0 0 31.596 38.139"
    >
      <g
        id="Grupo_105"
        data-name="Grupo 105"
        transform="translate(-35.404 -385)"
      >
        <path
          id="escudo-con-estrella"
          d="M33.955,38.139a.9.9,0,0,1-.719-.359,10.405,10.405,0,0,0-4.287-3.255c-.365-.114-.847-.223-1.406-.35-3.28-.745-9.385-2.132-9.385-9.217a26.18,26.18,0,0,1,1.716-9.481,12.683,12.683,0,0,0,.963-4.25,7.669,7.669,0,0,0-2.363-5.455.9.9,0,0,1,0-1.371L23.385.215a.9.9,0,0,1,1.27.1A5.243,5.243,0,0,0,28.516,1.73a5.1,5.1,0,0,0,3-.9,4.006,4.006,0,0,1,4.875,0,5.1,5.1,0,0,0,3,.9A5.239,5.239,0,0,0,43.26.31.909.909,0,0,1,44.524.215L49.436,4.4a.9.9,0,0,1,0,1.371,7.763,7.763,0,0,0-2.363,5.455,12.677,12.677,0,0,0,.963,4.25,26.18,26.18,0,0,1,1.716,9.481c0,7.086-6.105,8.472-9.385,9.217-.559.127-1.041.237-1.406.35a10.341,10.341,0,0,0-4.287,3.254A.9.9,0,0,1,33.955,38.139ZM20.368,5.154a9.439,9.439,0,0,1,2.27,6.073,14.208,14.208,0,0,1-1.061,4.837,24.294,24.294,0,0,0-1.618,8.894c0,5.647,4.8,6.738,7.982,7.46.6.135,1.111.252,1.544.388a11.1,11.1,0,0,1,4.47,3.049,11.1,11.1,0,0,1,4.47-3.049c.433-.135.948-.252,1.544-.388,3.179-.722,7.982-1.813,7.982-7.46a24.293,24.293,0,0,0-1.618-8.894,14.212,14.212,0,0,1-1.061-4.837,9.439,9.439,0,0,1,2.27-6.073L43.95,2.094a7.46,7.46,0,0,1-4.557,1.439A6.853,6.853,0,0,1,35.47,2.375,2.842,2.842,0,0,0,33.955,1.8a2.842,2.842,0,0,0-1.516.573,6.851,6.851,0,0,1-3.923,1.157,7.461,7.461,0,0,1-4.557-1.439ZM39.441,26.605a.9.9,0,0,1-.419-.1l-5.067-2.664L28.888,26.5a.9.9,0,0,1-1.307-.95l.968-5.642-4.1-4a.9.9,0,0,1,.5-1.537l5.665-.823L33.147,8.42a.9.9,0,0,1,1.616,0L37.3,13.554l5.665.823a.9.9,0,0,1,.5,1.537l-4.1,4,.968,5.642a.9.9,0,0,1-.888,1.054Zm-5.486-4.686a.9.9,0,0,1,.419.1l3.871,2.035-.739-4.31a.9.9,0,0,1,.259-.8L40.9,15.9l-4.327-.629a.9.9,0,0,1-.678-.493l-1.935-3.922-1.935,3.922a.9.9,0,0,1-.678.493l-4.327.629,3.131,3.052a.9.9,0,0,1,.259.8l-.739,4.31,3.871-2.035A.9.9,0,0,1,33.955,21.919Z"
          transform="translate(17.247 385)"
          fill="#f2a900"
        />
        <g
          id="Grupo_100"
          data-name="Grupo 100"
          transform="translate(57.042 394.534)"
        >
          <g id="Grupo_89" data-name="Grupo 89" transform="translate(0 0)">
            <path
              id="Trazado_291"
              data-name="Trazado 291"
              d="M379.753,20.171a.661.661,0,0,0-.924.146l-.7.966a.661.661,0,0,0,1.07.777l.7-.966A.661.661,0,0,0,379.753,20.171Z"
              transform="translate(-378.001 -20.045)"
              fill="#f2a900"
            />
          </g>
        </g>
        <g
          id="Grupo_101"
          data-name="Grupo 101"
          transform="translate(43.069 394.531)"
        >
          <g id="Grupo_91" data-name="Grupo 91" transform="translate(0 0)">
            <path
              id="Trazado_292"
              data-name="Trazado 292"
              d="M94.488,21.205l-.7-.966a.661.661,0,0,0-1.07.777l.7.966a.661.661,0,1,0,1.07-.777Z"
              transform="translate(-92.59 -19.966)"
              fill="#f2a900"
            />
          </g>
        </g>
        <g
          id="Grupo_102"
          data-name="Grupo 102"
          transform="translate(40.61 405.705)"
        >
          <g id="Grupo_93" data-name="Grupo 93" transform="translate(0)">
            <path
              id="Trazado_293"
              data-name="Trazado 293"
              d="M2.426,297.951a.661.661,0,0,0-.833-.425L.457,297.9a.661.661,0,1,0,.409,1.258L2,298.784A.661.661,0,0,0,2.426,297.951Z"
              transform="translate(0 -297.493)"
              fill="#f2a900"
            />
          </g>
        </g>
        <g
          id="Grupo_103"
          data-name="Grupo 103"
          transform="translate(50.408 412.298)"
        >
          <g id="Grupo_95" data-name="Grupo 95">
            <path
              id="Trazado_294"
              data-name="Trazado 294"
              d="M243.16,456.786a.661.661,0,0,0-.661.661v1.194a.661.661,0,0,0,1.322,0v-1.194A.661.661,0,0,0,243.16,456.786Z"
              transform="translate(-242.499 -456.786)"
              fill="#f2a900"
            />
          </g>
        </g>
        <g
          id="Grupo_104"
          data-name="Grupo 104"
          transform="translate(59.067 405.706)"
        >
          <g id="Grupo_97" data-name="Grupo 97">
            <path
              id="Trazado_295"
              data-name="Trazado 295"
              d="M463.785,297.915l-1.136-.369a.661.661,0,0,0-.409,1.258l1.136.369a.661.661,0,0,0,.409-1.258Z"
              transform="translate(-461.783 -297.513)"
              fill="#f2a900"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
